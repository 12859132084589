import React, { CSSProperties, useEffect, useState } from 'react';
import moment from 'moment';
import {
    ButtonComponent,
    DropdownFieldComponent,
    TextFieldComponent,
} from '@vivli/shared/components';
import {
    useDataPackageFileService,
    useDataPackageService,
} from '@vivli/shared/features/data-package/infrastructure/context';
import {
    IDataPackage,
    IDataPackageFileDescriptor,
} from '@vivli/shared/features/data-package/infrastructure/interface';
import { first } from 'rxjs/operators';
import { Color } from '@vivli/shared/theme';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { useStudiesService } from '@vivli/features/studies/infrastructure/context';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { LoggerService } from '@vivli/shared/infrastructure/service';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';
import { LoadIndicatorColorEnum } from '@vivli/shared/infrastructure/enum';

const containerStyle: CSSProperties = {
    marginBottom: 10,
    backgroundColor: Color.WHITE,
};

const formStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '5px',
};

const failedMessageStyle: CSSProperties = {
    marginLeft: 0,
    color: Color.WHITE,
    width: 110,
    height: 30,
    marginRight: 10,
    backgroundColor: Color.ERROR_RED,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const uploadingContainerStyle: CSSProperties = {
    color: Color.WHITE,
    width: 100,
    height: 30,
    marginRight: 10,
    backgroundColor: Color.VIVLI_LIGHT_BLUE,
    position: 'relative',
};

const uploadingStyle = (progressPercent: number): CSSProperties => ({
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'inline-block',
    width: progressPercent,
    height: 30,
    transition: 'width 8s',
    backgroundColor: Color.VIVLI_BLUE,
});

const buttonStyle = (minature?: boolean): CSSProperties => ({
    maxWidth: 100,
    minWidth: minature ? 'initial' : '100px',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between',
});

const deleteButtonStyle: CSSProperties = {
    '@media print': {
        display: 'none',
    },
    justifyContent: 'center',
    display: 'inline-flex',
    backgroundColor: 'white',
    color: Color.ERROR_RED,
    border: `1px solid ${Color.ERROR_RED}`,
    minWidth: 'initial',
} as React.CSSProperties;

const downloadButtonStyle: CSSProperties = {
    marginRight: '10px',
    '@media print': {
        display: 'none',
    },
    justifyContent: 'center',
    display: 'inline-flex',
    color: 'white',
    minWidth: 'initial',
} as CSSProperties;

const uploadingTextStyle: CSSProperties = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const buttonContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
};

const buttonImgStyle: CSSProperties = {
    width: '15px',
};

const buttonTxtStyle: CSSProperties = {
    marginRight: '10px',
};

interface DataPackageFileProps {
    dataPackageId: string;
    file: IDataPackageFileDescriptor;
    availableTypes?: string[];
    readOnly: boolean;
    allowDelete?: boolean;
    hideFileType?: boolean;
    showDateTime?: boolean;
    allowDownload?: boolean;
    recordDownloadHistory?: boolean;
    hasRequiredTypes?: boolean;
    requiredTypes?: string[];
    miniature?: boolean;
    useSecureStorage?: boolean;
    datasetMetaDataDoi?: string;
    studyOrgId?: string;
    expandedFilenameText?: boolean;
    onFileTypeChanged: (file: IDataPackageFileDescriptor) => void;
    onFileDeleted: (file: IDataPackageFileDescriptor, dataPackage: IDataPackage) => void;
}

export const DataPackageFileComponent = ({
    file,
    showDateTime,
    hideFileType,
    availableTypes,
    readOnly,
    onFileTypeChanged,
    allowDelete,
    allowDownload,
    recordDownloadHistory,
    dataPackageId,
    onFileDeleted,
    hasRequiredTypes,
    requiredTypes,
    miniature,
    useSecureStorage,
    datasetMetaDataDoi,
    studyOrgId,
    expandedFilenameText,
}: DataPackageFileProps) => {
    const [activeFile, setActiveFile] = useState<IDataPackageFileDescriptor>(file);
    const [isLoading, setIsLoading] = useState(false);

    const dataPackageFileService = useDataPackageFileService();
    const dataPackageService = useDataPackageService();
    const modalService = useModalService();
    const studiesService = useStudiesService();
    const user = useActiveUser();
    const filenameText = expandedFilenameText
        ? activeFile.name +
          ' - Uploaded: ' +
          moment(activeFile.updatedDateTime).format('M-D-YY h:mm a') +
          ', Uploaded by: ' +
          activeFile.uploadedByUserEmail
        : activeFile.name;

    const getDisplaySize = () => {
        if (!activeFile) {
            return '';
        }

        let { sizeInKb } = activeFile;
        let displaySize = '';

        // 0 is equal to false so specifically checking for null or undefined
        if (sizeInKb === undefined || sizeInKb === null) {
            return displaySize;
        }

        if (sizeInKb > 1024) {
            sizeInKb = sizeInKb / 1024;
            displaySize = `${parseFloat(sizeInKb.toString()).toFixed(2)}MB`;
        } else if (sizeInKb <= 0) {
            displaySize = '< 1 kB';
        } else {
            displaySize = `${parseFloat(sizeInKb.toString()).toFixed(2)}kB`;
        }

        return displaySize;
    };
    const canUserSendDataCount = (studyOrgId: string) => {
        // when data contributor downloads its own data , it doesn't count
        if (studyOrgId) {
            const orgs = user.orgMemberships.filter((org) => {
                return org.orgId === studyOrgId && org.isDataProvider;
            });
            return !(orgs && orgs.length > 0);
        }
        return false;
    };
    const handleFileDownload = () => {
        setIsLoading(true);
        //send data count

        if (
            datasetMetaDataDoi &&
            datasetMetaDataDoi != 'DOINotCreated' &&
            canUserSendDataCount(studyOrgId)
        ) {
            const fileId = activeFile.name;
            const fileIdentity = activeFile.datapackageDoiFileNumber
                ? activeFile.datapackageDoiFileNumber
                : fileId;
            studiesService
                .sendDataCountCollectionData(datasetMetaDataDoi, fileIdentity)
                .subscribe((sent) => {
                    LoggerService.log(`Download count is ${sent}`);
                });
        }

        dataPackageFileService
            .downloadFileUri(
                dataPackageId,
                activeFile.name,
                recordDownloadHistory || false,
                useSecureStorage
            )
            .pipe(first())
            .subscribe(
                (downloadLink) => {
                    //link is created and inserted into the DOM
                    //to avoid flickering happening if we just use window.open...
                    const a = document.createElement('a');

                    a.download = file.name;
                    a.href = downloadLink;

                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                    setIsLoading(false);
                },
                () => {
                    setIsLoading(false);
                    modalService.message('Unable to download file.', {
                        showBackground: false,
                        showContinueButton: true,
                        title: 'Error',
                    });
                }
            );
    };

    const handleFileDelete = () => {
        setIsLoading(true);
        dataPackageFileService
            .deleteFile(dataPackageId, activeFile.name, useSecureStorage)
            .pipe(first())
            .subscribe((dataPackage) => {
                onFileDeleted(activeFile, dataPackage);
            });
    };

    const handleFileTypeChanged = (value: string) => {
        setIsLoading(true);
        const updatedFile = {
            ...activeFile,
            type: value,
        };

        dataPackageService
            .addUpdateFile(dataPackageId, updatedFile)
            .pipe(first())
            .subscribe(() => {
                onFileTypeChanged(updatedFile);
                setIsLoading(false);
            });
    };

    const fieldHasError = () => {
        return (
            !hasRequiredTypes &&
            !requiredTypes?.some((rt) => activeFile.type === rt) &&
            'Missing Required File Types'
        );
    };

    useEffect(() => {
        if (!file) {
            return;
        }

        setActiveFile(file);
    }, [file]);

    return (
        <div style={containerStyle}>
            <div style={formStyle}>
                <TextFieldComponent
                    label="Filename"
                    style={{ width: '36%', boxShadow: 'none' }}
                    defaultValue={filenameText}
                    readonly={true}
                />

                {activeFile.updatedDateTime && showDateTime && (
                    <TextFieldComponent
                        label="Date"
                        style={{ width: '36%', boxShadow: 'none' }}
                        defaultValue={moment(activeFile.updatedDateTime).format('M-D-YY h:mm a')}
                        readonly={true}
                    />
                )}

                <TextFieldComponent
                    label="Size"
                    style={{ width: '12%', boxShadow: 'none' }}
                    defaultValue={getDisplaySize()}
                    readonly={true}
                />

                <TextFieldComponent
                    label="Uploaded By"
                    style={{
                        width: '18%',
                        minWidth: '120px',
                        boxShadow: 'none',
                        wordBreak: 'break-all',
                    }}
                    defaultValue={activeFile.uploadedByUserName}
                    readonly={true}
                />

                {!hideFileType && availableTypes && (
                    <DropdownFieldComponent
                        label={'File Type'}
                        placement="auto"
                        style={{
                            width: '20%',
                            boxShadow: 'none',
                            marginRight: '20px',
                        }}
                        readonly={readOnly || isLoading}
                        defaultValue={activeFile.type}
                        items={availableTypes.map((t) => ({
                            title: t,
                            value: t,
                        }))}
                        onChange={(value) => handleFileTypeChanged(value as string)}
                        error={fieldHasError()}
                        hidePopoverError={true}
                    />
                )}

                <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    {activeFile.isError && <div style={failedMessageStyle}>Upload Failed</div>}

                    {!activeFile.isError && (
                        <div
                            style={{
                                minWidth: miniature ? 'initial' : '120px',
                            }}
                        >
                            {!activeFile.isComplete && activeFile.percentUploaded !== undefined && (
                                <div style={uploadingContainerStyle}>
                                    <div style={uploadingStyle(activeFile.percentUploaded)} />
                                    <div style={uploadingTextStyle}>Uploading</div>
                                </div>
                            )}

                            {/*we have to check for percentUploaded here as well since old data did not correctly save*/}
                            {/*the isComplete property because of the old component's async issue */}
                            {(activeFile.isComplete ||
                                activeFile.percentUploaded === undefined) && (
                                <div style={buttonContainerStyle}>
                                    {allowDownload && (
                                        <ButtonComponent
                                            style={downloadButtonStyle}
                                            onClick={handleFileDownload}
                                            isLoading={isLoading}
                                            className="do-not-print"
                                            loaderColor={LoadIndicatorColorEnum.White}
                                        >
                                            <div style={buttonStyle(miniature)}>
                                                {!miniature && (
                                                    <span style={buttonTxtStyle}>Download</span>
                                                )}
                                                <img
                                                    src={AssetsConstant.DOWNLOAD_ARROW_WHITE}
                                                    style={buttonImgStyle}
                                                />
                                            </div>
                                        </ButtonComponent>
                                    )}

                                    {allowDelete && (
                                        <ButtonComponent
                                            style={deleteButtonStyle}
                                            onClick={handleFileDelete}
                                            isLoading={isLoading}
                                            className="do-not-print"
                                        >
                                            <div style={buttonStyle(miniature)}>
                                                {!miniature && (
                                                    <span style={buttonTxtStyle}>Delete</span>
                                                )}
                                                <img
                                                    src={AssetsConstant.RED_X_ICON}
                                                    style={buttonImgStyle}
                                                />
                                            </div>
                                        </ButtonComponent>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
