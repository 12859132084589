import {
    ISignedDocument,
    IUserBulkUpdateSpecifier,
    IUserDetails,
    IUsersApiService
} from '@vivli/features/users/infrastructure/interface';
import {IDataRequest} from '@vivli/features/data-requests/infrastructure/interface';
import {RestApi} from '@vivli/core/infrastructure/rest';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataTransformationService} from '@vivli/shared/infrastructure/service';
import {IApiRequestOptions} from "@vivli/core/infrastructure/interface";
import {IUser} from "@vivli/shared/infrastructure/interface";

export class UsersApiService extends RestApi implements IUsersApiService {
    getUserDetails = (userId: string): Observable<IUserDetails> => {
        return this.handleGet<IUserDetails>(`/users/${userId}/detail/`).pipe(map(DataTransformationService.formatUserDetails));
    }

    getUser = (userId: string, options?: IApiRequestOptions): Observable<IUser> => {
        return super.handleGet<IUser>(`/users/${userId}`, options);
    }

    getUserByEmail = (email: string) => {
        return this.handleGet<IUser>(`/users/${email}/email`)
    }

    getUsers = (populateDataRequests: boolean): Observable<IUserDetails[]> => {
        return this.handleGet<IUserDetails[]>(`/users/list/${populateDataRequests}`).pipe(map(DataTransformationService.formatUsersDetails));
    }

    setUserStatus = (userId: string, disable: boolean): Observable<void> => {
        return this.handleGet<void>(`/users/${userId}/${disable ? 'disable' : 'enable'}/`);
    }

    getUserDataRequests = (userId: string): Observable<IDataRequest[]> => {
        return this.handleGet<IDataRequest[]>(`/datarequests/${userId}/user`);
    }

    resetUserPassword = (userId: string): Observable<string> => {
        return this.handleGet<string>(`/users/${userId}/resetpassword/`);
    }

    trackUserActivity = (token?: string): Observable<void> => {
        return this.handleGet<void>(`/users/trackactivity`, {token});
    }

    setVivliAdminPermission = (userId: string, isVivliAdmin: boolean): Observable<IUserDetails> => {
        return this.handleGet<IUserDetails>(`/users/${userId}/admin?isVivliAdmin=${isVivliAdmin}`).pipe(map(DataTransformationService.formatUserDetails));
    }

    getVivliAdmins = (): Observable<IUserDetails[]> => {
        return this.handleGet<IUserDetails[]>('/users/listAdmins').pipe(map(DataTransformationService.formatUsersDetails));
    }

    updateSignedDocuments = (userId: string, documentName: string, documentValue: string, documentType: string, userIntent: string): Observable<ISignedDocument> => {
        return this.handlePost(`/users/${userId}/signedDocuments`, {documentName, documentValue, userIntent, documentType
        })
    }

    updateUserNote = (userId: string, note: string): Observable<IUser> => {
        return this.handlePut(`/users/${userId}/note/${note}`)
    }

    getUpdatePackage = (): Observable<string> => {
        return this.handlePost<string>('/users/getupdatepackage');
    }

    processBulkUpdate = (updateSpec: IUserBulkUpdateSpecifier): Observable<void> => {
        return this.handlePost<void>('/users/bulkupdateforusers', updateSpec);
    }
}
