﻿import React, { CSSProperties, useState } from 'react';
import { useModalService, useToastService } from '@vivli/shared/infrastructure/context';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { StudyRequestBehaviorEnum } from '@vivli/features/studies/infrastructure/enum';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { useDataRequestsService, useSharedDataContext } from '@vivli/features/data-requests/infrastructure/context';
import { finalize, first } from 'rxjs/operators';
import { PartnerPlatformModalComponent } from './partner-platform-modal.component';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { Color, Size, Styles } from '@vivli/shared/theme';
import { ButtonComponent, TranslationComponent } from '@vivli/shared/components';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { AdvancedDropdownMenuComponent } from './advanced-dropdown-menu.component';
import { IDropdownMenuItem } from '@vivli/shared/infrastructure/interface';
import { AdminApprovalConstant, AssetsConstant } from '@vivli/shared/infrastructure/constants';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum, TranslationKeyEnum } from '@vivli/shared/infrastructure/enum';
import { SearchResultRowInfoComponent } from './search-result-row-info.component';
import { useStudiesService } from '@vivli/features/studies/infrastructure/context';
import { ISearchStudy } from '@vivli/features/dashboard/infrastructure/interface';
import { useTranslation } from 'react-i18next';
import { useAuthService } from '@vivli/core/authentication';

const TITLE_FONT_STYLE: React.CSSProperties = {
    fontWeight: Size.FontWeight.Bold,
    fontSize: 20,
};
const SEARCH_DETAIL_STYLE: React.CSSProperties = {
    marginTop: Size.TEXTFIELD_ROWS,
    fontSize: Size.FontSize.Large,
    color: Color.TEXT_GRAY,
};
const SEARCH_DETAIL_STYLE_TOP: React.CSSProperties = {
    marginTop: Size.TEXTFIELD_ROWS,
    paddingTop: Size.PADDING,
    fontSize: Size.FontSize.Large,
    color: Color.TEXT_GRAY,
};
const loginButtonStyle: CSSProperties = {
    ...Styles.Button.BUTTON_VIVLI_BLUE,
    backgroundColor: Color.VIVLI_LIGHT_BLUE,
    fontWeight: Size.FontWeight.Regular,
    marginBottom: Size.PADDING,
};
const requestDetailsButtonStyle: CSSProperties = {
    ...Styles.Button.BUTTON_VIVLI_BLUE,
    backgroundColor: Color.LIGHT_GRAY,
    fontWeight: Size.FontWeight.Regular,
    color: Color.BLACK,
};
const flexColumnItemsCenterStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

interface SearchResultRowComponentProps {
    searchResult?: ISearchStudy;
    innerStyle?: CSSProperties;
    style?: CSSProperties;
    onViewStudyClick?: (e, searchResult: ISearchStudy) => void;
}

const toastSuccessResponse = 'Item Successfully Added to My Requests';

export const SearchResultRowComponent = ({ searchResult, innerStyle, style, onViewStudyClick }: SearchResultRowComponentProps) => {
    const activeUser = useActiveUser();
    const authService = useAuthService();
    const modalService = useModalService();
    const toastService = useToastService();
    const studiesService = useStudiesService();
    const dataRequestsService = useDataRequestsService();
    const { addDataRequest, myDataRequests } = useSharedDataContext().myDataRequests;
    const [isRequestStudyMenuOpen, setIsRequestStudyMenuOpen] = useState(false);
    const [isSubmittingToMyRequest, setIsSubmittingToMyRequest] = useState(false);
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const modalMaxWidth = '40em';
    const { t } = useTranslation();

    const containerStyle: CSSProperties = {
        height: '100%',
        minHeight: 190,
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
        paddingBottom: Size.PADDING,
        paddingLeft: Size.PADDING,
        paddingRight: Size.PADDING,
        paddingTop: Size.PADDING,
        backgroundColor: Color.WHITE,
        ...Styles.borderRadius(Size.SEARCH_BORDER_RADIUS_SIZE),
        ...innerStyle,
    };

    const modalText = (
        <div>
            Enter a descriptive name for your research project.
            <p>
                If this is an additional <TranslationComponent>study</TranslationComponent> you want to add to the same project, then
                instead of entering a new project name here, click cancel and choose your previous project name from the drop-down on the
                "Request <TranslationComponent style={{ textTransform: 'capitalize' }}>study</TranslationComponent>" button.
            </p>
        </div>
    );

    const handleSelectMenuOutsideClick = () => {
        setIsRequestStudyMenuOpen(false);
    };

    const handleRequestButtonClick = () => {
        setIsRequestStudyMenuOpen(!isRequestStudyMenuOpen);
    };

    const handleStudyAdded = (dataRequest: IDataRequest) => {
        addDataRequest(dataRequest);
        toastService.success(toastSuccessResponse);
    };

    const handleVivliStudy = (study: IStudy, title: string) => {
        dataRequestsService
            .addStudyToDataRequest({
                targetDataRequestTitle: title,
                studyId: study.id,
            })
            .pipe(
                first(),
                finalize(() => setIsSubmittingToMyRequest(false))
            )
            .subscribe(handleStudyAdded, modalService.error);
    };

    const handleExternalStudy = ({ externalStudyUri, id }: IStudy, title: string) => {
        const modalTitle = `Add ${t(TranslationKeyEnum.study)} available on a partner platform?`;
        const modalText = `The data package for this ${t(TranslationKeyEnum.study)} is provided on a partner platform. After the ${t(
            TranslationKeyEnum.study
        )} information is added to the data request you will be prompted to request data from that partner platform.`;

        modalService.confirm(modalText, {
            title: modalTitle,
            style: { maxWidth: modalMaxWidth, textAlign: 'left' },
            messageStyle: { textAlign: 'left' },
            onConfirm: () => {
                dataRequestsService
                    .addStudyToDataRequest({
                        targetDataRequestTitle: title,
                        studyId: id,
                    })
                    .pipe(
                        first(),
                        finalize(() => setIsSubmittingToMyRequest(false))
                    )
                    .subscribe(handleStudyAdded, modalService.error);

                modalService.custom(<PartnerPlatformModalComponent externalUri={externalStudyUri} />, {
                    showCloseButton: true,
                    closeOnOverlayClick: true,
                });
            },
            onCancel: () => setIsSubmittingToMyRequest(false),
        });
    };

    const handleStudy = (study, title) => {
        if (study.studyRequestBehavior === StudyRequestBehaviorEnum.Vivli) {
            handleVivliStudy(study, title);
        } else {
            handleExternalStudy(study, title);
        }
    };

    const handleAddStudy = (study: IStudy, receivedTitle: string, addingToNewRequest = false) => {
        if (!addingToNewRequest) {
            handleStudy(study, receivedTitle);
            return;
        }

        modalService.prompt(modalText, '', {
            title: receivedTitle,
            promptLabel: 'Research Project Name',
            style: { maxWidth: modalMaxWidth, textAlign: 'left' },
            messageStyle: { textAlign: 'left' },
            confirmText: 'OK',
            cancelText: 'Cancel',
            onConfirm: ({ comment }) => {
                handleStudy(study, comment);
            },
            onCancel: () => setIsSubmittingToMyRequest(false),
        });
    };

    const addToCart = (selectedItem?: IDropdownMenuItem) => {
        const isNewRequest = selectedItem.value === 'addnewrequest';
        const receivedTitle = isNewRequest ? 'New Research Data Request' : selectedItem.title;
        setIsSubmittingToMyRequest(true);

        studiesService
            .getStudyAnonymous(searchResult.id)
            .pipe(first())
            .subscribe(
                (study) => {
                    handleAddStudy(study, receivedTitle, isNewRequest);
                },
                (error) => {
                    modalService.error(error);
                    setIsSubmittingToMyRequest(false);
                }
            );

        setIsRequestStudyMenuOpen(false);
    };

    if (!searchResult) {
        return null;
    }
    const phase = AdminApprovalConstant.phaseLabels.phaseTable.find((a) => a.value === searchResult.phase);

    return (
        <div
            style={{
                paddingBottom: Size.PADDING,
                ...style,
            }}
        >
            <div style={containerStyle}>
                <div>
                    <div>
                        {!activeUser && (
                            <ButtonComponent
                                style={loginButtonStyle}
                                onClick={() => authService.login()}
                                className="searchResult_logInToRequest"
                            >
                                Log in to Request <TranslationComponent style={{ textTransform: 'capitalize' }}>study</TranslationComponent>
                            </ButtonComponent>
                        )}
                        {activeUser && (
                            <div>
                                <AdvancedDropdownMenuComponent
                                    items={(myDataRequests || [])
                                        .filter((dr) => dr.userId === activeUser.userId && dr.status === DataRequestStatusEnum.Draft)
                                        .map(
                                            (dataRequest) =>
                                                ({
                                                    title: dataRequest.requestTitle || dataRequest.id,
                                                    value: dataRequest.id,
                                                    dataRequest,
                                                } as IDropdownMenuItem)
                                        )
                                        .concat({
                                            title: '+ Add New Request',
                                            value: 'addnewrequest',
                                        } as IDropdownMenuItem)}
                                    onClickOutside={() => handleSelectMenuOutsideClick()}
                                    onItemClick={(item) => addToCart(item)}
                                >
                                    <ButtonComponent
                                        isLoading={isSubmittingToMyRequest}
                                        style={
                                            {
                                                ...Styles.Button.BUTTON_VIVLI_BLUE,
                                                backgroundColor: isRequestStudyMenuOpen ? Color.VIVLI_DARK_BLUE : Color.VIVLI_LIGHT_BLUE,
                                                backgroundImage: `url(${AssetsConstant.DOWN_CHEVRON_WHITE})`,
                                                paddingRight: Size.PADDING,
                                                fontWeight: Size.FontWeight.SemiBold,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'right 20px center',
                                                backgroundSize: '20px',
                                                ':active': 'inherit',
                                            } as CSSProperties
                                        }
                                        onClick={() => handleRequestButtonClick()}
                                        className="request_study_menu"
                                        data-test-id={'request_study_menu'}
                                    >
                                        Request{' '}
                                        <TranslationComponent
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            study
                                        </TranslationComponent>
                                    </ButtonComponent>
                                </AdvancedDropdownMenuComponent>
                            </div>
                        )}
                        {activeUser && (
                            <div
                                style={{
                                    marginBottom: Size.PADDING,
                                }}
                            />
                        )}
                        <ButtonComponent
                            style={requestDetailsButtonStyle}
                            onClick={(e) => onViewStudyClick(e, searchResult)}
                            className="searchResult_viewDetails"
                        >
                            View <TranslationComponent style={{ textTransform: 'capitalize' }}>study</TranslationComponent> Details
                        </ButtonComponent>

                        <div style={flexColumnItemsCenterStyle}>
                            <span style={SEARCH_DETAIL_STYLE_TOP}>
                                {isAmr ? 'Number of isolates:' : 'Number enrolled:'}
                                <span style={{ color: Color.BLACK }}>
                                    {isAmr
                                        ? searchResult.numberOfIsolates !== 0
                                            ? searchResult.numberOfIsolates.toString()
                                            : '-'
                                        : searchResult.actualEnrollment !== 0
                                        ? searchResult.actualEnrollment.toString()
                                        : '-'}
                                </span>
                            </span>
                            {!isAmr && (
                                <span
                                    style={{
                                        ...SEARCH_DETAIL_STYLE,
                                        color: Color.BLACK,
                                        marginTop: 8,
                                    }}
                                >
                                    {phase && phase.label}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        flex: 'auto',
                        overflow: 'hidden',
                        paddingRight: Size.PADDING,
                    }}
                >
                    <div
                        style={{
                            ...TITLE_FONT_STYLE,
                            color: 'rgb(0, 0, 0)',
                            marginBottom: Size.PADDING,
                        }}
                    >
                        {isAmr ? searchResult.program : searchResult.title}
                    </div>
                    <SearchResultRowInfoComponent searchResult={searchResult} isAmr={isAmr} />
                </div>
            </div>
        </div>
    );
};
