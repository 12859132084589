﻿import React, {CSSProperties} from "react";
import {CheckboxFormFieldComponent, fourAcrossStyle, threeAcrossStyle} from "@vivli/shared/components";
import {Styles} from "@vivli/shared/theme";

const fourAcrossNoShadowStyle : CSSProperties = {
    ...fourAcrossStyle,
    boxShadow: 'none'
}

const threeAcrossNoShadowStyle : CSSProperties = {
    ...threeAcrossStyle,
    boxShadow: 'none'
}

interface StudyDatasetIpdOptionsComponentProps{
    extendedMetadataOption: boolean
}

export const StudyDatasetIpdOptionsComponent = ({extendedMetadataOption}: StudyDatasetIpdOptionsComponentProps) => {

    //extendedMetadataOption is for CT only, adds checkbox for setting this
    const styleToUse : CSSProperties = extendedMetadataOption
        ? fourAcrossNoShadowStyle
        : threeAcrossNoShadowStyle

    return (
        <>
            <div style={Styles.FORM_ROW}>
                <CheckboxFormFieldComponent
                    name={'downloadableStudyIPDDataPackage'}
                    label={'Data Package is downloadable'}
                    truncateLabel={false}
                    style={styleToUse}
                    inputStyle={{background: 'transparent'}}
                />
                {extendedMetadataOption &&
                    <CheckboxFormFieldComponent
                        name={'showExtendedStudyMetadata'}
                        label={'Show extended Study Metadata in User Search Screen'}
                        truncateLabel={false}
                        style={styleToUse}
                    />
                }
                <CheckboxFormFieldComponent
                    name={'bypassDataContributorReview'}
                    label={'Bypass Data Contributor Review'}
                    truncateLabel={false}
                    style={styleToUse}
                />
                <CheckboxFormFieldComponent
                    name={'bypassIRPReview'}
                    label={'Bypass IRP Review'}
                    truncateLabel={false}
                    style={styleToUse}
                />
            </div>
        </>
    )
}
