import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import './init';
import App from './app/app';
import { environment } from '@vivli/core/environments';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// strict mode is set to true for local development by default, you can disable it in .env.local as follows
// VITE_USE_STRICT_MODE=false
if (!environment.useStrictMode) {
    root.render(<App />);
} else {
    // using console.log in this one case as it's important to know if we accidentally got into strict mode
    console.log('%cStrict mode enabled (NOTE, for development only)', 'color: red; background-color: black; font-size: larger');

    // enable strict mode for development only as it causes the following effects:
    // React docs link: https://react.dev/reference/react/StrictMode
    // 1. components re-render an extra time to find bugs caused by impure rendering.
    // 2. components re-run effects an extra time to find bugs caused by missing Effect cleanup
    // 3. components will be checked for usage of deprecated APIs

    root.render(
        <StrictMode>
            <App />
        </StrictMode>
    );
}
