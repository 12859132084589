﻿import React, {CSSProperties} from "react";
import {useDatasetContext} from "@vivli/features/datasets/infrastructure/context";
import {MultiSelectDropdownFieldComponent, TextFieldReadOnlyFormComponent} from "@vivli/shared/components";
import {useConfigService} from "@vivli/core/infrastructure/context";

const teamGridRowStyle: CSSProperties = {
    display: 'grid',
    gridTemplateRows: 'auto',
    rowGap: '20px',
    marginTop: 10
}
const teamGridColumnStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '20% 20% 20% auto',
    columnGap: '20px'
}

export const DatasetResearchTeamComponent = () => {
    const {study} = useDatasetContext();
    const config = useConfigService();
    const creditRoles = config?.creditRoles;
    const creditOptions = Object.entries(creditRoles).map((creditRole) => ({id: creditRole[0], contributorRole: creditRole[1]}));

    return (
        <div style={teamGridRowStyle}>
            {study.researchTeam.map((member, index) => (
                <div style={teamGridColumnStyle} key={index}>
                    <TextFieldReadOnlyFormComponent
                        label={'Given Name'}
                        defaultValue={member.givenName}
                    />
                    <TextFieldReadOnlyFormComponent
                        label={'Family Name'}
                        defaultValue={member.familyName}
                    />
                    <TextFieldReadOnlyFormComponent
                        label={'ORCID iD'}
                        defaultValue={member.orcidId}
                    />
                    <MultiSelectDropdownFieldComponent
                        label={'CRediT Role(s)'}
                        value={member.creditRoles}
                        items={creditOptions}
                        objectKey={'id'}
                        labelKey={'contributorRole'}
                        isDisabled={true}
                    />
                </div>
            ))}
        </div>
    )
}
