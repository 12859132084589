import React, {CSSProperties, Fragment, useContext, useEffect, useLayoutEffect, useState} from 'react';
import {useActiveUser} from '@vivli/core/infrastructure/context';
import {useAuthService} from '@vivli/core/authentication';
import {useNavigate} from 'react-router-dom';
import {Color, Size, Styles} from '@vivli/shared/theme';
import {useUserMenuRoutes} from '../hooks/use-user-menu-routes.hook';
import {PopoutMenuComponent, PopoutPopoutRenderer, PopoutTargetRenderer,} from '@vivli/shared/components';
import {usePortalConfigHook} from '@vivli/shared/infrastructure/hook';
import {AssetsConstant} from '@vivli/shared/infrastructure/constants';

const popoutTargetContainerStyle = (isOpen: boolean): CSSProperties => ({
    ...Styles.NO_SELECT,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: isOpen ? Color.WHITE : null,
    transition: `background-color ${Size.HOVER_TRANSITION_TIME}s`,
});

const popoutTargetImgContainerStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    ...Styles.Transition.HOVER_OPACITY_TRANSITION,
};

const roleTextStyle = (isOpen: boolean): CSSProperties => ({
    marginLeft: Size.PADDING / 2,
    marginRight: Size.PADDING / 2,
    color: isOpen ? Color.VIVLI_DARK_BLUE : Color.WHITE,
    fontSize: Size.FontSize.Medium,
});

const popoutContainerStyle: CSSProperties = {
    height: Size.HEADER_HEIGHT / 2,
    color: Color.DARK_GRAY,
    paddingBottom: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
    fontSize: Size.FontSize.Medium,
    fontWeight: Size.FontWeight.Bold,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    ...Styles.Transition.HOVER_OPACITY_TRANSITION,
};

export const ActiveUserMenuComponent = () => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const activeUser = useActiveUser();
    const { updateProfile, updatePassword, logout } = useAuthService();
    const navigate = useNavigate();
    const { getSearchLink } = usePortalConfigHook();

         let userMenuRoutes = useUserMenuRoutes().filter((element) => {
            if (Object.keys(element).length !== 0) {
                return true;
            }
        });


    const getUserRoleName = () => {
        if (!activeUser) return '';
        const userName = activeUser.name.toUpperCase();
        const { isVivliAdmin, isOperationsAdmin } = activeUser;

        if (isOperationsAdmin) return `${userName}  (Operations Admin)`;
        if (isVivliAdmin) return `${userName} (Vivli Admin)`;

        return userName;
    };

    const popoutTargetRenderer: PopoutTargetRenderer = (isOpen) => {

        const PopoutTarget = () => (
            <div
                style={popoutTargetContainerStyle(isOpen)}
                onClick={() => setMenuIsOpen(!menuIsOpen)}
                className="main_menu"
                data-test-id={'main_menu'}
            >
                <div
                    key="avatar-container"
                    style={popoutTargetImgContainerStyle}
                >
                    <img
                        src={
                            isOpen
                                ? AssetsConstant.avatarInverseIcon
                                : AssetsConstant.avatarIcon
                        }
                    />
                    <div style={roleTextStyle(isOpen)}>{getUserRoleName()}</div>
                    <img
                        src={
                            isOpen
                                ? AssetsConstant.downArrowDarkIcon
                                : AssetsConstant.downArrowWhiteIcon
                        }
                    />
                </div>
            </div>
        );

        return <PopoutTarget />;
    };

    const popoutPopoutRenderer: PopoutPopoutRenderer = (
        style,
        onItemSelected
    ) => {
        const MenuItem = ({ title, onClick, dataId }) => (
            <div
                style={popoutContainerStyle}
                className={
                    'menuItem_' +
                    (title ? title.replace(/[^\w]/gi, '_') : 'empty')
                }
                onClick={() => {
                    onClick();
                    onItemSelected();
                    setMenuIsOpen(false);
                }}
                data-test-id={dataId}
            >
                {title}
            </div>
        );

        return (
            <div
                style={{
                    ...style,
                    backgroundColor: Color.WHITE,
                    boxShadow: Size.MENU_BOX_SHADOW,
                    width: '100%',
                }}
            >
                <MenuItem
                    title="Search"
                    onClick={() => navigate(getSearchLink())}
                    dataId={'MenuItem_Search'}
                />
                {userMenuRoutes.map(
                    ({ title, url, defaultTab, dataId }, index) => (
                        <MenuItem
                            key={index}
                            title={title}
                            onClick={() => {
                                navigate(`/admin/${url}/${defaultTab}`);
                            }}
                            dataId={dataId}
                        />
                    )
                )}
                {!activeUser?.partner && (
                    <Fragment>
                        <MenuItem
                            title="Edit My Profile"
                            onClick={() => updateProfile()}
                            dataId={'MenuItem_EditMyProfile'}
                        />
                        <MenuItem
                            title="Change Password"
                            onClick={() => updatePassword()}
                            dataId={'MenuItem_ChangePassword'}
                        />
                        <MenuItem
                            title="Log Out"
                            onClick={() => logout()}
                            dataId={'MenuItem_LogOut'}
                        />
                    </Fragment>
                )}
            </div>
        );
    };

    return (
        <PopoutMenuComponent
            popoutRenderer={popoutPopoutRenderer}
            targetRenderer={popoutTargetRenderer}
            isOpen={menuIsOpen}
            setIsOpen={setMenuIsOpen}
            onClickOutside={() => setMenuIsOpen(false)}
            disableReposition
        />
    );
};

