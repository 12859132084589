import React, { useRef, useState } from 'react';
import { StatusUpdateContext, useDataRequestContext, useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { IContextWrapper, IStatusUpdate } from '@vivli/shared/infrastructure/interface';
import { UseFormReturn } from 'react-hook-form';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { first } from 'rxjs/operators';
import { useModalService, useToastService } from '@vivli/shared/infrastructure/context';

export const StatusUpdateContextWrapper = ({ children }: IContextWrapper) => {
    const { updateDataRequest, dataRequest } = useDataRequestContext();
    const toastService = useToastService();
    const modalService = useModalService();
    const dataRequestService = useDataRequestsService();
    const formApiRef = useRef<UseFormReturn<IStatusUpdate>>();

    const [isSubmittingForm, setIsSubmittingForm] = useState(false);

    const handleSuccess = (_dataRequest: IDataRequest) => {
        updateDataRequest(_dataRequest);
        toastService.success('Successfully saved your Status Update');
        setIsSubmittingForm(false);
    };

    const handleFailure = (err: string) => {
        modalService.error(`An error occurred submitting your status update. Please try again or contact Vivli support. Message: ${err}`);
        setIsSubmittingForm(false);
    };

    const submitStatusUpdate = () => {
        setIsSubmittingForm(true);
        const statusUpdate: IStatusUpdate = formApiRef.current.getValues();
        dataRequestService.postStatusUpdate(dataRequest.id, statusUpdate).pipe(first()).subscribe(handleSuccess, handleFailure);
    };

    const setFormApi = (formApi: UseFormReturn<IStatusUpdate>) => {
        formApiRef.current = formApi;
    };

    const provider = {
        submitStatusUpdate,
        isSubmittingForm,
        setFormApi,
    };

    return (
        <StatusUpdateContext.Provider value={provider}>
            <div style={{ height: '100%', width: '100%' }}>{children}</div>
        </StatusUpdateContext.Provider>
    );
};
