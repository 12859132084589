﻿import {FormProvider} from 'react-hook-form';
import {
    AdminHeaderComponent,
    AdminHeaderTitleComponent,
    ButtonComponent, FieldHeaderComponent,
    GoBackLinkComponent
} from "@vivli/shared/components";
import {BulkUploadStudiesSelectorsComponent} from "./bulk-upload-studies-selectors.component";
import {Size, Styles} from "@vivli/shared/theme";
import {DocumentsDataPackageFeature} from "@vivli/shared/features/data-package/feature";
import React, {CSSProperties, useState} from "react";
import {first} from "rxjs/operators";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {IStudyBulkUploadSpecifier} from "@vivli/features/studies/infrastructure/interface";
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {useStudiesService} from "@vivli/features/studies/infrastructure/context";
import {useBulkUploadFormHook} from "@vivli/features/studies/infrastructure/hook";
import {useNavigate} from "react-router-dom";

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    width: '100%',
    height: '100%'
}
const innerContainerStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
}
const uploadContainerStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    flex: 'auto',
}
const goBackStyle: CSSProperties = {
    justifyContent: 'flex-start',
    marginBottom: 10
}

const availableTypes = ['NCT IDs (Option 1)', 'Non NCT IDs (Option 2)', 'Non NCT IDs (Option 3)'];

interface BulkUploadStudiesFormComponentProps {
    bulkUploadId: string
}
export const BulkUploadStudiesFormComponent = (
    {
        bulkUploadId,
    }: BulkUploadStudiesFormComponentProps
) => {
    const formApi = useBulkUploadFormHook();
    const modalService = useModalService();
    const studiesService = useStudiesService();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const navigate = useNavigate();

    const [showContinueButton, setShowContinueButton] = useState(true);
    const [showUpload, setShowUpload] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const modalMessage = 'Studies from the specified sheet are being added to the Vivli system. You will receive an email when the process is complete. You will now be taken to the Draft Studies section.';

    const handleContinue = () => {
        setShowContinueButton(false);
        setShowUpload(true);
    }

    const handleError = (error) => {
        setIsSubmitting(false);
        modalService.error(error);
    }

    const handleProcessUploadSheet = (form) => {
        setIsSubmitting(true);
            const uploadSpecifier: IStudyBulkUploadSpecifier = {
                dataPackageId: bulkUploadId,
                organizationId: form.dataContributorOrganization.orgId,
                organizationName: form.dataContributorOrganization.orgName,
                organizationCode: form.dataContributorOrganization.orgCode,
                irpOrgId: form.requestsApprovedByOrganization.orgId,
                irpOrgName: form.requestsApprovedByOrganization.orgName,
                curationOrgId: form.curationOrganization.orgId,
                curationOrgName: form.curationOrganization.orgName,
                curationQcOrgId: form.curationQcOrganization.orgId,
                curationQcOrgName: form.curationQcOrganization.orgName,
                bulkUploadContentType: form.contentType,
                downloadable: form.isDownloadable,
                fileName: null, // loaded on the server
                isAmr: isAmr
            }
            modalService.message(modalMessage);
            studiesService.bulkUploadStudies(uploadSpecifier)
                .pipe(first())
                .subscribe(() => {
                    setIsSubmitting(false);
                    navigate(`/admin/studies`);
                }, handleError);
    }

    return (
        <FormProvider {...formApi}>
            <div style={containerStyle} className={'scrolly'}>
                <AdminHeaderComponent>
                    <div style={{flexDirection: 'column'}}>
                        <GoBackLinkComponent style={goBackStyle}/>
                        <AdminHeaderTitleComponent title={'Bulk Upload Studies'}/>
                    </div>
                </AdminHeaderComponent>
                <div style={innerContainerStyle}>
                    <BulkUploadStudiesSelectorsComponent />
                </div>

                {showContinueButton &&
                    <div style={{ ...Styles.FORM_ROW }}>
                        <ButtonComponent
                            style={Styles.Button.BUTTON_VIVLI_BLUE}
                            onClick={handleContinue}
                            isLoading={isSubmitting}
                            className='bulkUpload_continue'
                            disabled={!formApi.formState.isValid}
                        >
                            Continue
                        </ButtonComponent>
                    </div>
                }

                {showUpload &&
                    <>
                        <div style={uploadContainerStyle}>
                            <FieldHeaderComponent title='SHEET TO UPLOAD' />
                            <DocumentsDataPackageFeature
                                dataPackageId={bulkUploadId}
                                availableTypes={availableTypes}
                                useSecureStorage={false}
                            />
                        </div>

                        <div style={{ paddingBottom: 100 }}>
                            <ButtonComponent
                                isLoading={isSubmitting}
                                style={Styles.Button.BUTTON_VIVLI_BLUE}
                                onClick={formApi.handleSubmit(handleProcessUploadSheet)}
                                className='bulkUpload_processSheet'
                            >
                                Process Upload Sheet
                            </ButtonComponent>
                        </div>
                    </>
                }

            </div>
        </FormProvider>
    )
}
