﻿import {
    IDataRequest,
    IRequestedStudy,
    IRequestedStudyModification
} from '@vivli/features/data-requests/infrastructure/interface';
import {LinkButtonComponent} from '@vivli/shared/components';
import React, {CSSProperties, useEffect, useState} from 'react';
import {useModalService} from '@vivli/shared/infrastructure/context';
import {ICheckboxMenuItem} from '@vivli/shared/infrastructure/interface';
import {first} from 'rxjs/operators';
import {useDataRequestsService} from '@vivli/features/data-requests/infrastructure/context';
import {useRequestedStudyBehavior} from '@vivli/features/studies/infrastructure/hook';
import {RequestedStudyTypeEnum} from '@vivli/features/data-requests/infrastructure/enum';
import {useDataPackageService} from '@vivli/shared/features/data-package/infrastructure/context';
import {useAssignedAppType} from '@vivli/core/infrastructure/hook';
import {AssignedAppTypeEnum} from '@vivli/shared/infrastructure/enum';
import {Color} from '@vivli/shared/theme';

interface EditAdvancedOptionsButtonComponentProps {
    requestedStudy: IRequestedStudy;
    dataRequest: IDataRequest;
    isVivliAdmin: boolean;
    isDisabled: boolean;
    updateDataRequest: (dataRequest: IDataRequest) => void;
    onOptionsChange: (requestedStudyMods: IRequestedStudyModification, dataRequest: IDataRequest) => void;
    isEditable: boolean;
}

const titleStyle: CSSProperties = {
    color: Color.BLACK,
    marginRight: '10px',
    whiteSpace: 'pre-wrap',
};

const editButtonStyle: CSSProperties = {
    width: 150,
    marginTop: -4,
};

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    width: '40%',
    minWidth: '392px',
    marginLeft: '4px',
};

export const EditAdvancedOptionsButtonComponent = ({
    requestedStudy,
    dataRequest,
    isVivliAdmin,
    isDisabled,
    updateDataRequest,
    onOptionsChange,
    isEditable, //component will show without button if not
}: EditAdvancedOptionsButtonComponentProps) => {
    const { getOptionsToModify, optionsConstants, optionOnTitlesConstants, canChangeIrpReview, getStudyIdForDisplay } =
        useRequestedStudyBehavior();
    const modalService = useModalService();
    const dataRequestsService = useDataRequestsService();
    const dataPackageService = useDataPackageService();
    const assignedAppType = useAssignedAppType();

    const [currentOptionsSelections, setCurrentOptionsSelections] = useState([]);
    const [templateDataSubmitted, setTemplateDataSubmitted] = useState<boolean>(false);
    const [templateDataText, setTemplateDataText] = useState<string>('');

    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const studyText = isAmr ? 'dataset' : 'study';
    const templateDataMessage = `This ${studyText} currently has a stored data package.  If you would like to use that stored package for this request, uncheck the option "${optionOnTitlesConstants.thisRequestOnlyIpd}".`;
    const studyDisplayId = getStudyIdForDisplay(requestedStudy);
    const titleMessageBody = `settings for the ${studyText} ${studyDisplayId} in this data request only`;

    const handleResult = (selections) => {
        if (!selections || selections === currentOptionsSelections) {
            return;
        }
        const optionsToModify = getOptionsToModify(requestedStudy, dataRequest, isVivliAdmin, selections);
        if (optionsToModify) {
            updateRequestedStudy(optionsToModify);
        }
    };

    const handleRequestError = (error: string) => {
        modalService.error(error);
    };
    const handleClick = (e) => {
        e.stopPropagation();

        return modalService.confirm(templateDataText, {
            title: `Advanced ${titleMessageBody}`,
            style: { textAlign: 'left', minWidth: '50em' },
            messageStyle: { textAlign: 'left' },
            multiSelectOptions: getCheckboxes(),
            showMultiSelect: true,
            requireSelectOption: false,
            showTextAreaPrompt: false,
            showPrompt: false,
            confirmText: 'OK',
            cancelText: 'Cancel',
            onConfirm: ({ selections }) => {
                handleResult(selections);
            },
        });
    };

    const getCheckboxes = () => {
        const checkboxValues: ICheckboxMenuItem[] = [];
        const isUnlisted = requestedStudy.requestedStudyType === RequestedStudyTypeEnum.Unlisted;

        //don't show this request only checkbox for unlisted studies
        //it is always true and will confuse users
        if (!isUnlisted) {
            checkboxValues.push({
                title: optionOnTitlesConstants.thisRequestOnlyIpd,
                value: optionsConstants.thisRequestOnlyIpd,
                checked: requestedStudy.thisRequestOnlyIpd,
            });
        }
        //only show downloadable checkbox for Vivli Admin CT mode,
        //AMR always downloadable and shouldn't be changed
        if (!isAmr && isVivliAdmin) {
            checkboxValues.push({
                title: optionOnTitlesConstants.downloadable,
                value: optionsConstants.downloadable,
                checked: requestedStudy.downloadableRequestedStudyIPDDataPackage,
            });
        }
        //only show IRP Review change option for vivli admin, certain statuses only
        if (canChangeIrpReview(dataRequest, requestedStudy, isVivliAdmin)) {
            checkboxValues.push({
                title: optionOnTitlesConstants.skipIrpReview,
                value: optionsConstants.skipIrpReview,
                checked: requestedStudy.bypassIRPReview,
            });
        }
        return checkboxValues;
    };
    const updateRequestedStudy = (requestedStudyMods: IRequestedStudyModification) => {
        dataRequestsService
            .modifyRequestedStudySettings(requestedStudyMods)
            .pipe(first())
            .subscribe((dataRequest) => {
                if (updateDataRequest) {
                    //update the data request context because we have one
                    updateDataRequest(dataRequest);
                } else {
                    //no data request context, but update the
                    //requested study in the parent to keep it in sync!
                    onOptionsChange(requestedStudyMods, dataRequest);
                }
            }, handleRequestError);
    };

    useEffect(() => {
        if (!requestedStudy || !requestedStudy.studyIPDDataPackageId) {
            return;
        }
        const initialSelections = [];
        if (requestedStudy.thisRequestOnlyIpd) {
            initialSelections.push(optionsConstants.thisRequestOnlyIpd);
        }
        if (requestedStudy.downloadableRequestedStudyIPDDataPackage) {
            initialSelections.push(optionsConstants.downloadable);
        }
        if (requestedStudy.bypassIRPReview) {
            initialSelections.push(optionsConstants.skipIrpReview);
        }
        setCurrentOptionsSelections(initialSelections);

        if (requestedStudy.thisRequestOnlyIpd) {
            const dataPackageId = requestedStudy.studyIPDDataPackageId;
            const sub = dataPackageService.getDataPackageSubmitStatus(dataPackageId).pipe(first()).subscribe(setTemplateDataSubmitted);
            if (templateDataSubmitted) {
                setTemplateDataText(templateDataMessage);
            }

            return () => {
                sub.unsubscribe();
            };
        }
    }, [requestedStudy, dataRequest, templateDataSubmitted]);

    return (
        <div style={containerStyle}>
            <div style={titleStyle}>Settings:</div>
            {isEditable && (
                <LinkButtonComponent
                    title={`Edit advanced ${titleMessageBody}`}
                    style={editButtonStyle}
                    onClick={handleClick}
                    disabled={isDisabled}
                >
                    Edit Settings
                </LinkButtonComponent>
            )}
        </div>
    );
};
