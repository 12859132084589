import React, {createContext, useContext} from "react";
import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";
import {IOrganization} from "@vivli/features/organizations/infrastructure/interface";
import {IVirtualMachine} from "@vivli/features/virtual-machine/infrastructure/interface";
import {UseFormReturn} from "react-hook-form";

interface IDataRequestContext {
    dataRequest: IDataRequest;
    updateDataRequest: (dataRequest: IDataRequest) => void;
    showApprovalButtons: boolean;
    setShowApprovalButtons: (show: boolean) => void;
    organizations: IOrganization[];
    virtualMachine: IVirtualMachine;
    showResearchResults: boolean;
    isUpdatingStatus: boolean;
    setIsUpdatingStatus: (isUpdating: boolean) => void;
    handleEditTitleClick: (formApi: UseFormReturn<IDataRequest, object>) => void;
    isSavingDataRequest: boolean;
    isSubmittingDataRequest: boolean;
    handleFormSave: (formApi: UseFormReturn<IDataRequest, object>, submitRequest: boolean) => void;
    handleDataPackageCreation: (formApi: UseFormReturn<IDataRequest, object>) => void;
    getResetToDraftVisibility: () => boolean;
    updateIsSaving: (status: boolean) => void;
    isAdminEditing: boolean;
    setIsAdminEditing: (isEditing: boolean) => void;
    ownsDataRequest: () => boolean;
}

export const DataRequestContext = createContext<IDataRequestContext>(null);

export const useDataRequestContext = () => useContext(DataRequestContext);


