import React, { CSSProperties, ReactNode } from 'react';
import { IModal, IModalApi } from '@vivli/shared/infrastructure/interface';
import { Color, Size, Styles } from '@vivli/shared/theme';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';
import { ButtonComponent } from '../buttons/button.component';

export interface ModalComponentProps {
    children?: ReactNode;
    modalApi: IModalApi;
    modal: IModal;
}

const defaultContainerStyle: CSSProperties = {
    ...Styles.Transition.OPACITY_TRANSITION,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundRepeat: 'no-repeat',
    zIndex: 9000,
};

const baseBodyStyle: CSSProperties = {
    display: 'flex',
    backgroundColor: 'white',
    flexDirection: 'column',
};

const modalBodyStyle: CSSProperties = {
    ...Styles.CENTERED_FLEX,
    ...Styles.CENTERED_IN_BLOCK,
    ...baseBodyStyle,
    minWidth: 100,
    borderRadius: 15,
    boxShadow: Size.BOX_SHADOW,
    textAlign: 'center',
};

const closeButtonStyle: CSSProperties = {
    width: 30,
    height: 30,
    borderRadius: 30,
    borderBottom: '1px solid white',
    borderLeft: '1px solid white',
    borderRight: '1px solid white',
    borderTop: '1px solid white',
    backgroundColor: Color.VIVLI_RED,
    fontSize: 20,
    color: 'white',
    cursor: 'pointer',
    position: 'absolute',
    top: -15,
    right: -15,
    textAlign: 'center',
    paddingTop: '3px',
};

const fillStyle: CSSProperties = { width: '100%', height: '100%' };

const bodyStyle: CSSProperties = {
    color: 'black',
    overflowWrap: 'anywhere',
    ...fillStyle,
};

const fullScreenStyle: CSSProperties = {
    ...baseBodyStyle,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    overflow: 'auto',
};

export const ModalComponent = ({ modalApi, modal }: ModalComponentProps) => {
    const { onOverlayClick, visible, id, showCloseButton, component, closeOnOverlayClick, fullScreen } = modal;

    const handleOverlayClick = () => {
        onOverlayClick && onOverlayClick();
        closeOnOverlayClick && modalApi.dismiss(id);
    };

    const modalContainerStyle: CSSProperties = {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        backdropFilter: modal.showBackground ? '' : 'blur(5px)',
    };

    const modalOverlayStyle: CSSProperties = {
        backgroundColor: 'rgb(170, 170, 170)',
        opacity: modal.showBackground ? 1 : 0.5,
        height: '100%',
        width: '100%',
        backgroundImage: modal.showBackground ? `url(${AssetsConstant.VIVLI_BACKGROUND})` : 'none',
        backgroundRepeat: 'repeat-x',
    };

    const containerStyle: CSSProperties = {
        ...defaultContainerStyle,
        opacity: visible ? 1 : 0,
        pointerEvents: !visible ? 'none' : null,
        display: visible ? 'block' : 'none',
    };

    let activeBodyStyle: CSSProperties = { ...modalBodyStyle, ...modal.style };
    if (fullScreen) {
        activeBodyStyle = { ...fullScreenStyle, ...modal.style };
    }

    const closeButton = (
        <div
            style={closeButtonStyle}
            onClick={() => {
                modalApi.dismiss(id);
            }}
            className="modal_close_X"
        >
            X
        </div>
    );

    return (
        <div style={containerStyle}>
            <div style={modalContainerStyle}>
                <div style={modalOverlayStyle} onClick={handleOverlayClick} />
            </div>
            <div style={activeBodyStyle}>
                {showCloseButton && closeButton}
                <div style={fillStyle}>
                    <div style={bodyStyle}>{component}</div>
                    {showCloseButton && (
                        <div style={{ marginBottom: 40 }}>
                            <ButtonComponent
                                className="modal_close"
                                style={{ ...Styles.Button.BUTTON_VIVLI_BLUE }}
                                onClick={() => modalApi.dismiss(id)}
                            >
                                Close
                            </ButtonComponent>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
