import React, {CSSProperties, useState} from 'react';
import {InfoButtonComponent} from "./search-buttons/info-button.component";
import {SearchButtonComponent} from "./search-buttons/search-button.component";
import {WelcomeModalComponent} from "@vivli/shared/components";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {useSearchContext} from "@vivli/features/search/infrastructure/context";

const inputButtonsContainerStyle: CSSProperties = {
    display: 'flex',
    paddingTop: '0'
}

const closeButtonStyle = (isHovering: boolean): CSSProperties => ({
    cursor: 'pointer',
    height: '60px',
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: isHovering ? 'grey' : 'initial'
})

export const SearchInputButtonsComponent = () => {
    const modalService = useModalService();
    const [isHovering, setIsHovering] = useState(false);
    const {toggleIsShowingResults, isShowingResults} = useSearchContext()

    const handleInfoButtonClick = () => {
        const modalId = modalService.custom(<WelcomeModalComponent onClose={() => modalService.dismiss(modalId)}/>)
    }

    return <div style={inputButtonsContainerStyle}>
        {!isShowingResults && <>
            <InfoButtonComponent onClick={handleInfoButtonClick}/>
            <SearchButtonComponent onClick={toggleIsShowingResults}/>
        </>}
        {isShowingResults && <div
            style={closeButtonStyle(isHovering)}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onClick={toggleIsShowingResults}>CLOSE</div>}
    </div>
}
