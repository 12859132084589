﻿import React, { CSSProperties } from 'react';
import {
    ButtonComponent,
    CheckboxFormFieldComponent,
    FieldHeaderComponent,
    HintPopoverComponent,
} from '@vivli/shared/components';
import {
    adminDivStyle,
    outerTabStyle,
    tabContentContainerStyle,
} from '../shared/styles';
import { useListingRequestContext } from '@vivli/features/listing-request/infrastructure/context';
import { ListingRequestStatusEnum } from '@vivli/features/listing-request/infrastructure/enum';
import {
    useActiveUser,
    useConfigService,
} from '@vivli/core/infrastructure/context';
import { useWatch } from 'react-hook-form';
import { signAgreementHint } from '@vivli/features/listing-request/infrastructure/constants';
import { Color, Size } from '@vivli/shared/theme';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const checkboxFieldStyle: CSSProperties = {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
};

export const AgreementsComponent = () => {
    const user = useActiveUser();
    const config = useConfigService();
    const { listingRequest, isReadOnly } = useListingRequestContext();

    const alreadySignedWatch = useWatch({ name: 'hasBeenSigned' });

    const status = listingRequest?.status
        ? ListingRequestStatusEnum[listingRequest?.status]
        : null;
    const isApproved = status
        ? status === ListingRequestStatusEnum.Approved
        : false;
    const isWithdrawn = status
        ? status === ListingRequestStatusEnum.Withdrawn
        : false;
    const isButtonEnabled = !isApproved && !isWithdrawn && !alreadySignedWatch;
    const buttonText = isButtonEnabled
        ? 'Sign Data Contribution Agreement'
        : 'Signed Agreement Complete';
    const isCheckboxEnabled =
        user?.isVivliAdmin && !isReadOnly && !isApproved && !isWithdrawn;
    const whatsNextText = "WHAT'S NEXT";

    const handleDocusignClick = () => {
        window.open(config?.docusignUrl, '_blank');
    };

    return (
        <div style={outerTabStyle} className={'scrolly'}>
            <div style={tabContentContainerStyle}>
                <FieldHeaderComponent title={'AGREEMENTS'} />
                <div style={{ width: '70%' }}>
                    <p>
                        The Principal Investigator and an Institutional Official
                        will need to read, acknowledge, and sign this Data
                        Contribution Agreement (DCA). If your institution
                        already has a Master DCA in place, we do not require
                        institutional signature for future submissions. If you
                        are unsure whether your institution has a Master
                        agreement in place, please reach out to{' '}
                        <a href={'mailto:support@vivli.org'} target={'_blank'}>
                            support@vivli.org
                        </a>
                        .
                    </p>
                    <p>
                        Click below to start the signing process.
                        <HintPopoverComponent
                            position={'top'}
                            hintText={signAgreementHint}
                            maxWidth={Size.POPOVER_MAX_WIDTH}
                            popoverColor={Color.TEXT_LIGHT_BLUE}
                            popoverArrowColor={Color.TEXT_LIGHT_BLUE}
                            icon={AssetsConstant.INFO_ICON}
                        />
                    </p>
                    <ButtonComponent
                        onClick={handleDocusignClick}
                        disabled={!isButtonEnabled}
                    >
                        {buttonText}
                    </ButtonComponent>
                    <p>
                        At a minimum, Vivli will make the data available for 10
                        years. On an ongoing basis, Vivli evaluates its data
                        holdings with regard to maintaining access and reserves
                        the right to discontinue the distribution of data
                        collections when deemed appropriate.
                    </p>

                    {user.isVivliAdmin && (
                        <div style={adminDivStyle}>
                            <p>
                                For the Vivli Admin - click Edit to indicate if
                                the agreement has been signed.
                            </p>
                            <CheckboxFormFieldComponent
                                name={'hasBeenSigned'}
                                label={'Agreement has been signed and approved'}
                                truncateLabel={false}
                                style={checkboxFieldStyle}
                                disabled={!isCheckboxEnabled}
                            />
                        </div>
                    )}
                </div>
                <FieldHeaderComponent title={whatsNextText} />
                <div style={{ width: '70%' }}>
                    <p>
                        Once you have initiated the Data Contribution Agreement
                        signing process, please click the Submit button, if
                        available, to notify Vivli to begin processing the
                        study.
                    </p>
                    <p>
                        Once the study is processed and the Data Contribution
                        Agreement signed, the study will appear in the Vivli
                        Search and you will receive an email from Vivli inviting
                        you to upload the anonymized data. Follow the link in
                        the email or return to the Submissions tab, choose this
                        submission and choose "Upload Data."
                    </p>
                </div>
            </div>
        </div>
    );
};
