﻿import React, {CSSProperties} from "react";
import {StudyApproveRejectButtonComponent} from "../study-approve-reject-button.component";
import {useAdminStudyPermissions, useAdminStudyStatusChange} from "@vivli/features/studies/infrastructure/hook";
import {useStudyContext} from "@vivli/features/studies/infrastructure/context";
import {AdminHeaderButtonComponent} from "@vivli/shared/components";
import {StudyStatusEnum} from "@vivli/features/studies/infrastructure/enum";

const containerStyle: CSSProperties = {
    display: 'flex'
}

interface StudyWorkflowButtonsComponentProps {
    onSaveClick?: () => void;
    onStatusChange?: (statusDetails) => void;
    formHasErrors?: boolean;
    formIsDirty?: boolean;
}
export const StudyWorkflowButtonsComponent = (
    {
        onSaveClick,
        onStatusChange,
        formHasErrors,
        formIsDirty
    }: StudyWorkflowButtonsComponentProps
) => {
    const {
        study,
        studyIpdPackageSubmitted,
        handleApproveCurationSubmit,
        isSavingStudy
    } = useStudyContext();
    const {userCanApprove} = useAdminStudyPermissions(study, studyIpdPackageSubmitted);
    const studyStatusChange = useAdminStudyStatusChange(study);
    const statusObject = studyStatusChange.getCurationStatusObject();

    const handleOnApprove = () => {
        if (study.status === StudyStatusEnum.CurationQaReviewInProgress){
            handleApproveCurationSubmit();
        }
        else{
            return onStatusChange(statusObject?.approveProps);
        }
    }

    return (
        <div style={containerStyle}>
            {userCanApprove() &&
                <StudyApproveRejectButtonComponent
                    onRejectStudyClick={() => onStatusChange(statusObject?.rejectProps)}
                    onApproveStudyClick={handleOnApprove}
                    onSoftRejectStudyClick={() => onStatusChange(statusObject?.softRejectProps)}
                    approveLabel={statusObject?.approveProps?.buttonText}
                    rejectLabel={statusObject?.rejectProps?.buttonText}
                    softRejectLabel={statusObject?.softRejectProps?.buttonText}
                    showApprove={statusObject?.userCanApprove}
                    showReject={statusObject?.userCanReject}
                    showSoftReject={statusObject?.userCanSoftReject}
                    isLoading={isSavingStudy}
                />
            }
            {!userCanApprove() &&
                <div>{statusObject?.permFailText}</div>
            }

            <AdminHeaderButtonComponent
                title={'Save'}
                onClick={onSaveClick}
                isDisabled={!formIsDirty || formHasErrors}
                isLoading={isSavingStudy}
            />
        </div>
    )
}
