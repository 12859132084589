import React from 'react';
import {SearchInputComponent} from "../search-input.component";
import {
    filterGroupContainerStyle,
    innerScrollStyle,
    searchBarContainerStyle,
    searchFiltersContainerStyle
} from "../search-filters.styles";
import {FirstFilterGroupComponent} from "./amr-filter-groups/first-filter-group.component";
import {SecondFilterGroupComponent} from "./amr-filter-groups/second-filter-group.component";
import {ThirdFilterGroupComponent} from "./amr-filter-groups/third-filter-group.component";
import {FourthFilterGroupComponent} from "./amr-filter-groups/fourth-filter-group.component";
import {FifthFilterGroupComponent} from "./amr-filter-groups/fifth-filter-group.component";
import {useAmrSearchOptionsContext} from "@vivli/features/search/infrastructure/context";

export const AmrSearchFiltersComponent = () => {
    const {setSearchString} = useAmrSearchOptionsContext();

    return <>
        <div style={searchBarContainerStyle}>
            <SearchInputComponent disableInput={true} onChange={setSearchString} />
        </div>
        <div style={searchFiltersContainerStyle}>
            <div style={innerScrollStyle}>
                <div style={filterGroupContainerStyle}>
                    <FirstFilterGroupComponent />
                </div>

                <div style={filterGroupContainerStyle}>
                    <SecondFilterGroupComponent />
                </div>

                <div style={filterGroupContainerStyle}>
                    <ThirdFilterGroupComponent />
                </div>

                <div style={filterGroupContainerStyle}>
                    <FourthFilterGroupComponent />
                    <FifthFilterGroupComponent />
                </div>
            </div>
        </div>
    </>
}
