import React, {CSSProperties} from 'react';
import {useResearchEnvironmentContext} from "@vivli/features/data-requests/infrastructure/context";
import {VmStatusEnum} from "@vivli/features/virtual-machine/infrastructure/enum";
import {Color, Size} from "@vivli/shared/theme";

const containerStyle: CSSProperties = {
    width: 630,
    textAlign: 'center',
    color: Color.LIGHTER_GRAY,
    fontWeight: Size.FontWeight.Regular,
    fontSize: Size.FontSize.Larger,
}

export const VmProvisionedInfoTextComponent = () => {
    const {provisionStatus} = useResearchEnvironmentContext();

    const getInfoText = () => {
        switch (provisionStatus) {
            case VmStatusEnum.Running:
                return 'Stopping your environment simply shuts it down, but deprovisioning your environment will permanently delete it.'
            case VmStatusEnum.Stopped:
                return 'Starting your environment will make it so you can connect, but deprovisioning your environment will permanently delete it.'
        }
    }

    return (
        <div style={containerStyle}>
            <span>
                {getInfoText()}
            </span>
        </div>
    )
}
