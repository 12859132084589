import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useSearchContext } from '@vivli/features/search/infrastructure/context';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { SearchInputButtonsComponent } from './search-input-buttons.component';
import { useConfigService } from '@vivli/core/infrastructure/context';
import { useNavigate } from 'react-router';

const containerStyle: CSSProperties = {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
};

const inputContainerStyle: CSSProperties = {
    display: 'flex',
    flexGrow: '1',
    paddingLeft: '10px',
    position: 'relative',
};

const inputStyle: CSSProperties = {
    height: '70px',
    fontSize: '20px',
    outline: 'none',
    border: 'none',
    width: '100%',
};

const overlayStyle = (inputDisabled?: boolean): CSSProperties => ({
    fontSize: '20px',
    fontFamily: 'sans-serif',
    fontWeight: 400,
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none',
    position: 'absolute',
    top: inputDisabled ? '-10px' : '23px',
    left: '15px',
    color: 'grey',
    cursor: inputDisabled ? 'default' : 'text',
});

interface SearchInputComponent {
    disableInput?: boolean;
    onChange: (filterString: string) => void;
}

export const SearchInputComponent = ({ disableInput = false, onChange }: SearchInputComponent) => {
    const { initialSearch } = useConfigService();
    const navigate = useNavigate();

    const { toggleIsShowingResults, filterString, searchInput, setSearchInput } = useSearchContext();
    const [showOverlay, setShowOverlay] = useState(!filterString && !initialSearch);
    const inputRef = useRef<HTMLInputElement>();

    const debouncedInputSubRef = useRef(new Subject<string>());

    const handleInputChange = (value: string) => {
        const newValue = encodeURIComponent(value || '');

        if (newValue?.length > 0) {
            navigate(`?search=${newValue}`, { replace: true });
        } else {
            navigate(window.location.pathname, { replace: true });
        }

        onChange(newValue);
    };

    const handleOnChange = (e) => {
        const value = e.target.value;
        handleShowOverlay(value);
        setSearchInput(value);
        debouncedInputSubRef.current.next(value);
    };

    const handleShowOverlay = (e) => {
        const show = (e.length || 0) <= 0;

        if (show !== showOverlay) {
            setShowOverlay(show);
        }
    };

    const handleOverlayClick = () => {
        if (disableInput) {
            return;
        }

        inputRef.current.focus();
    };

    useEffect(() => {
        const sub = debouncedInputSubRef.current.pipe(debounceTime(500)).subscribe((value) => handleInputChange(value));

        return () => {
            sub?.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (!inputRef.current) {
            return;
        }

        const keydownEvent = fromEvent(inputRef.current, 'keydown');

        const keydownSub = keydownEvent.pipe(filter((e: any) => e.key === 'Enter')).subscribe(() => {
            toggleIsShowingResults();
        });

        return () => {
            keydownSub.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (searchInput.length > 0) {
            setShowOverlay(false);
        } else {
            setShowOverlay(true);
        }
    }, [searchInput]);

    return (
        <div style={containerStyle}>
            <div style={inputContainerStyle}>
                {showOverlay && (
                    <div style={overlayStyle(disableInput)} onClick={handleOverlayClick}>
                        What are you looking for today?
                    </div>
                )}
                {!disableInput && (
                    <input style={inputStyle} ref={inputRef} data-test-id={'search-box'} onChange={handleOnChange} value={searchInput} />
                )}
            </div>
            <SearchInputButtonsComponent />
        </div>
    );
};
