﻿import React, { CSSProperties, useEffect } from 'react';
import {
    AvailableIpdContentTypeEnum,
    StudyRequestBehaviorEnum,
} from '@vivli/features/studies/infrastructure/enum';
import { useOrganizations } from '@vivli/features/organizations/infrastructure/hook';
import {
    CheckboxFormFieldComponent,
    DropdownFormFieldComponent,
    FieldHeaderComponent,
    HintPopoverComponent,
    LoadIndicatorCenteredComponent,
    TextFormFieldComponent,
} from '@vivli/shared/components';
import { Size, Styles } from '@vivli/shared/theme';
import { IOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { useFormContext, useWatch } from 'react-hook-form';

const twentyFiveFlexStyle: CSSProperties = {
    flex: '1 1 26%',
    maxWidth: '25%',
    marginBottom: Size.FIELD_SEPARATION,
};
const twentyEightFlexStyle: CSSProperties = {
    flex: '1 1 28%',
    maxWidth: '28%',
    marginBottom: Size.FIELD_SEPARATION,
};
const fortyFiveFlexStyle: CSSProperties = {
    flex: '1 1 46%',
    maxWidth: '45%',
    marginBottom: Size.FIELD_SEPARATION,
    marginRight: Size.PADDING
};
const fiftyFlexStyle: CSSProperties = {
    flex: '1 1 50%',
    maxWidth: '49%',
    marginBottom: Size.FIELD_SEPARATION,
};
const endFiftyFlexStyle: CSSProperties = {
    flex: '1 1 50%',
    maxWidth: '49%',
    marginBottom: Size.FIELD_SEPARATION,
    marginRight: Size.PADDING
};
const flexRowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
};
const checkboxStyle: CSSProperties = {
    flexDirection: 'row-reverse',
};
const overrideCheckboxStyle: CSSProperties = {
    marginLeft: 15,
    ...checkboxStyle,
};

const IPDContentTypeLabels = {
    [AvailableIpdContentTypeEnum.Basic]:
        'Basic – IPD, Protocol, Statistical Analysis Plan, Data Dictionary',
    [AvailableIpdContentTypeEnum.Full]:
        'Expanded – Basic plus Analysis-ready Dataset, redacted Clinical Study Report, Annotated Case Report Form, or other',
};
const StudyRequestBehaviorLabels = {
    [StudyRequestBehaviorEnum.Vivli]: 'Vivli Standard Study Request Process',
    [StudyRequestBehaviorEnum.External]: 'External Partner Study Request Process',
};

const packageSuppliedOnSubmissionHint =
    'To provide the Data Package including IPD at the time the study is submitted, check this item.  If this is not checked, then you will be prompted to provide the Data Package when the first researcher requests the data for the study.';
const downloadableHint =
    "If the data associated with this study are directly downloadable to a researcher's desktop, check this item.  If this item is not checked (the default) then the data will only be made available in a highly protected and access controlled research environment.  Note that in both cases, the researcher will create a data request that will go through a review and approval process before the Data Package is made available.";
const extendedStudyMetadataHint =
    'With this option selected, extended metadata about this study (design population, etc. will be visible to people who find this study in a search.';

const alphabeticalSort = (a, b) => {
    return a.title < b.title ? -1 : 1;
};
const getOptions = (list: IOrganization[]) => {
    return list?.map(({ id, name }) => ({ value: id, title: name })).sort(alphabeticalSort);
};

export const VivliDetailsComponent = () => {
    const formApi = useFormContext();
    const { organizations, irpApproverOrgs } = useOrganizations();

    const orgIdItems = getOptions(organizations);
    const irpOrgIdItems = getOptions(irpApproverOrgs);
    const ipdContentTypeItems = Object.keys(AvailableIpdContentTypeEnum).map((o) => ({
        value: o,
        title: IPDContentTypeLabels[o],
    }));
    const studyBehaviorItems = Object.keys(StudyRequestBehaviorEnum).map((o) => ({
        value: o,
        title: StudyRequestBehaviorLabels[o],
    }));

    const orgIdValue = useWatch({ name: 'orgId' });
    const irpOrgIdValue = useWatch({ name: 'irpOrgId' });

    useEffect(() => {
        if (!organizations) {
            return;
        }
        const orgName = organizations?.find((o) => o.id === orgIdValue).name;
        const orgCode = organizations?.find((o) => o.id === orgIdValue).code;
        formApi.setValue('orgName', orgName, { shouldDirty: true });
        formApi.setValue('orgCode', orgCode, { shouldDirty: true });
    }, [orgIdValue]);
    useEffect(() => {
        if (!irpApproverOrgs) {
            return;
        }
        const irpOrgName = irpApproverOrgs?.find((o) => o.id === irpOrgIdValue).name;
        formApi.setValue('irpOrgName', irpOrgName, { shouldDirty: true });
    }, [irpOrgIdValue]);

    if (!organizations || !irpApproverOrgs) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <>
            <FieldHeaderComponent title="VIVLI DETAILS" />
            <div style={Styles.FORM_ROW}>
                <DropdownFormFieldComponent
                    name={'orgId'}
                    style={twentyFiveFlexStyle}
                    label={'Data Contributor Organization'}
                    items={orgIdItems}
                />
                <DropdownFormFieldComponent
                    name={'irpOrgId'}
                    style={twentyEightFlexStyle}
                    label={'Data Requests to be Approved by'}
                    items={irpOrgIdItems}
                    hidePopoverError={true}
                />
                <DropdownFormFieldComponent
                    name={'ipdContentType'}
                    style={fortyFiveFlexStyle}
                    label={'Available Data Package'}
                    items={ipdContentTypeItems}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <div style={flexRowStyle}>
                    <CheckboxFormFieldComponent
                        name={'downloadableStudyIPDDataPackage'}
                        label={'Data Package will be available for direct download by researchers'}
                        style={checkboxStyle}
                    />
                    <HintPopoverComponent
                        position={'top' as any}
                        hintText={downloadableHint}
                        maxWidth={Size.POPOVER_MAX_WIDTH}
                    />
                </div>
                <div style={flexRowStyle}>
                    <CheckboxFormFieldComponent
                        name={'showExtendedStudyMetadata'}
                        label={'Show extended metadata for this study'}
                        style={checkboxStyle}
                    />
                    <HintPopoverComponent
                        position={'top' as any}
                        hintText={extendedStudyMetadataHint}
                        maxWidth={Size.POPOVER_MAX_WIDTH}
                    />
                </div>
            </div>
            <div style={Styles.FORM_ROW}>
                <DropdownFormFieldComponent
                    name={'studyRequestBehavior'}
                    style={fiftyFlexStyle}
                    label={'Process when Study is Requested'}
                    items={studyBehaviorItems}
                />
                <TextFormFieldComponent
                    name={'externalStudyUri'}
                    style={endFiftyFlexStyle}
                    label={'External Site URL'}
                    hint={
                        'Copy the URL of an external site that will provide the IPD for this study.'
                    }
                />
            </div>
            <div style={{ ...Styles.FORM_ROW, justifyContent: 'start' }}>
                <TextFormFieldComponent
                    name={'alternativeMetadataDocumentUri'}
                    style={fiftyFlexStyle}
                    label={'Alternate URL for documents to be used during curation process'}
                    hint={
                        'To supply a URL for a publicly accessible document to be used during the curation process, copy the URL here.'
                    }
                />
                <CheckboxFormFieldComponent
                    name={'overrideDisplayDefaults'}
                    label={'Override Display Defaults'}
                    style={overrideCheckboxStyle}
                />
            </div>
        </>
    );
};
