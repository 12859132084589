import React, {CSSProperties} from 'react';
import {VmProvisionedInfoTextComponent} from "./vm-provisioned-info-text.component";
import {
    useDataRequestContext,
    useResearchEnvironmentContext
} from "@vivli/features/data-requests/infrastructure/context";
import {VmProvisionedButtonComponent} from "./vm-provisioned-button.component";
import {VmStatusEnum} from "@vivli/features/virtual-machine/infrastructure/enum";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {Color, Size} from "@vivli/shared/theme";

const containerStyle: CSSProperties = {marginBottom: Size.PADDING}

const buttonsContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '25px',
    marginBottom: '15px',
}

const innerContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    width: 760,
    justifyContent: 'space-between',
    marginBottom: 15,
}
const hintTextStyle: CSSProperties = {
    color: '#939090',
    width: '800px',
    textAlign: 'center',
    marginTop: '35px',
    boxShadow: '0 0 5px #aeabab',
    padding: '20px 0',

}

export const VmProvisionedButtonsComponent = () => {
    const {
        addDataPackagesToVm,
        provisionStatus,
        startVm,
        stopVm,
        deProvisionVm
    } = useResearchEnvironmentContext();
    const {dataRequest} = useDataRequestContext();
    const user = useActiveUser();

    const userIsOwner = user.userId === dataRequest?.userId;
    const userDetails = dataRequest
        ?.researchTeam
        .researchTeamDetails
        .find(x => x.userId === user.userId);
    const userIsLeadResearcher = userDetails?.isLeadResearcher;

    const userCanDeProvision = userIsLeadResearcher || userIsOwner;

    return (
        <div style={containerStyle}>
            <div style={buttonsContainerStyle}>
                <div style={innerContainerStyle}>

                    <VmProvisionedButtonComponent
                        title={'Add Data Packages'}
                        color={Color.VIVLI_BLUE}
                        onClick={addDataPackagesToVm}
                    />

                    {provisionStatus === VmStatusEnum.Stopped && (
                        <VmProvisionedButtonComponent
                            title={'Start Research Environment'}
                            color={Color.VIVLI_GREEN}
                            onClick={startVm}
                        />
                    )}

                    {provisionStatus === VmStatusEnum.Running && (
                        <VmProvisionedButtonComponent
                            title={'Stop Environment'}
                            color={Color.VIVLI_RED}
                            onClick={stopVm}
                        />
                    )}

                    {userCanDeProvision && (
                        <VmProvisionedButtonComponent
                            title={'Deprovision Environment'}
                            color={Color.VIVLI_RED}
                            onClick={deProvisionVm}
                        />
                    )}

                </div>

                <VmProvisionedInfoTextComponent/>
            </div>
            <div style={hintTextStyle}>
                If you have been informed that data has recently been provided for any of your studies,
                click "Add Data Packages” to add that new data to your research environment. If you want
                to bring data or scripts into the research environment, reach out to support@vivli.org and
                we will assist you.
            </div>
        </div>
    )
}
