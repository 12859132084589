import React from 'react';
import { Color } from '@vivli/shared/theme';
import { ButtonComponent } from './buttons/button.component';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

interface ExportCsvButtonProps {
    onClick: () => void;
    style?: React.CSSProperties;
    title?: string;
}

const buttonContainerStyle: React.CSSProperties = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '130px',
};
const buttonStyle: React.CSSProperties = {
    background: 'transparent',
    color: Color.VIVLI_BLUE,
    boxShadow: 'none',
    minWidth: '60px',
    paddingLeft: '0px',
};

export const ExportCsvButtonComponent = (props: ExportCsvButtonProps) => {
    const { onClick, style, title } = props;

    return (
        <div  data-test-id={'export_csv_button'}  style={{ ...buttonContainerStyle, ...style }}>
            <ButtonComponent onClick={onClick} style={buttonStyle} title={title || 'Export CSV'}>
                <img src={AssetsConstant.DOWNLOAD_ARROW} style={{ height: '25px' }} />
            </ButtonComponent>
        </div>
    );
};
