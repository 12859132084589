﻿import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import React, {useEffect, useState} from 'react';
import { useMyDataRequests } from '@vivli/features/data-requests/infrastructure/hook';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { ISharedDataContext, SharedDataContext } from '@vivli/features/data-requests/infrastructure/context';

export const SharedDataContextWrapper = ({ children }: IContextWrapper) => {
    const [showRequestAddedBadge, setShowRequestAddedBadge] = useState(false);
    const [hasListingRequests, setHasListingRequests] = useState<boolean>();

    const { setMyDataRequests, myDataRequests, draftCount, notApprovedCount, archivedCount, inProgressCount, fulfilledCount } =
        useMyDataRequests();

    const popRequestAddedBadge = () => {
        setShowRequestAddedBadge(true);
        setTimeout(() => {
            setShowRequestAddedBadge(false);
        }, 5000);
    };

    const addDataRequest = (dataRequest: IDataRequest) => {
        if (!myDataRequests.some((r) => r.id === dataRequest.id)) {
            setMyDataRequests([...myDataRequests, dataRequest]);
        }
        popRequestAddedBadge();
    };


    const provider: ISharedDataContext = {
        myDataRequests: {
            myDataRequests,
            setMyDataRequests,
            draftCount,
            notApprovedCount,
            archivedCount,
            inProgressCount,
            fulfilledCount,
            showRequestAddedBadge,
            popRequestAddedBadge,
            addDataRequest,
        },
        showSubmissionMenuLink: hasListingRequests,
        setHasListingRequests

    };

    return <SharedDataContext.Provider value={provider}>{children}</SharedDataContext.Provider>;
};
