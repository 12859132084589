import React from "react";
import {Styles} from "@vivli/shared/theme";
import {
    LinkFieldComponent,
    TextFieldReadOnlyFormComponent,
    oneAcrossStyle,
    threeSmallSplitAcrossStyle,
    twoAcrossStyle,
} from "@vivli/shared/components";

export const AmrDatasetAdminDetailsComponent = ({study}) => {
    const showLinkDoi = study.studyMetadataDoi.indexOf('DOINotCreated') < 0;

    return (
        <div style={{width: '100%'}}>
            <div style={Styles.FORM_ROW}>
                {showLinkDoi
                    ? <LinkFieldComponent
                        linkTitle={study.studyMetadataDoi}
                        linkContent={study.studyMetadataDoi}
                        label={'DOI'}
                        style={twoAcrossStyle}
                    />
                    : <TextFieldReadOnlyFormComponent
                        label={'DOI'}
                        style={twoAcrossStyle}
                        defaultValue={study.studyMetadataDoi}
                        readonly={true}
                    />

                }
                <TextFieldReadOnlyFormComponent
                    label={'AMR ID'}
                    style={threeSmallSplitAcrossStyle}
                    defaultValue={`VIV${study.doiStem}`}
                    readonly={true}
                />
                <TextFieldReadOnlyFormComponent
                    label='Dataset ID'
                    style={threeSmallSplitAcrossStyle}
                    defaultValue={study.sponsorProtocolId}
                    readonly={true}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <LinkFieldComponent
                    linkTitle={study.studyIPDDataPackageDoi}
                    linkContent={study.studyIPDDataPackageDoi}
                    label={'Available Data Package DOIs'}
                    style={oneAcrossStyle}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <TextFieldReadOnlyFormComponent
                    label={'Data Contributor Organization Name'}
                    style={oneAcrossStyle}
                    defaultValue={study.orgName}
                    readonly={true}
                />
            </div>
        </div>
    );
}
