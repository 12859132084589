﻿import React, {CSSProperties, useEffect, useState} from "react";
import {
    DatePickerFormFieldComponent,
    DropdownFormFieldComponent,
    FieldHeaderComponent,
    TextAreaFormFieldComponent,
    TextFormFieldComponent,
    fourAcrossStyle,
    oneAcrossStyle,
    threeAcrossStyle
} from "@vivli/shared/components";
import {PhaseEnum, RecruitmentStatusEnum} from "@vivli/features/studies/infrastructure/enum";
import {IDropdownMenuItem, IUser} from "@vivli/shared/infrastructure/interface";
import {IStudy} from "@vivli/features/studies/infrastructure/interface";
import {Size, Styles} from "@vivli/shared/theme";
import {PicoComponent} from "@vivli/features/datasets/components";
import {StudyDatasetJsonComponent} from "../study-dataset-json.component";

const twoThirdsStyle: CSSProperties = {
    flex: '1 1 64%',
    maxWidth: '64%',
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,
    boxShadow: Size.BOX_SHADOW,
}

const topStyle: CSSProperties = {
    width: '97%',
    marginLeft: Size.PADDING,
    marginRight: Size.PADDING,
}

interface AdminCtStudyDetailComponentProps {
    user?: IUser,
    study?: IStudy,
}

export const AdminCtStudyDetailComponent = (
    {   user, study}: AdminCtStudyDetailComponentProps
) => {

    const [phaseItems, setPhaseItems] = useState<IDropdownMenuItem[]>();
    const [currentPhase, setCurrentPhase] = useState<PhaseEnum>(study.phase);

    const [recruitmentStatusItems, setRecruitmentStatusItems] = useState<IDropdownMenuItem[]>();
    const [currentRecruitmentStatus, setCurrentRecruitmentStatus] = useState<RecruitmentStatusEnum>(study.overallStatus);

    const locations = study?.locationsOfStudySites?.map(option => ` ${option.name} (${option.numberSites})`).toString();

    const userIsReadOnly = !user.isVivliAdmin;
    const picoAndStudyDesignJsonString = JSON.stringify(study.picoAndStudyDesign, null, '\t');
    const extractedConditions = study?.extractedConditions.join();
    const extractedInterventions = study?.extractedInterventions.join();

    useEffect(() => {
        if (!study) {
            return;
        }
        const phaseVals = Object.values(PhaseEnum);
        const phaseKeys = Object.keys(PhaseEnum);
        const menuItems = Object.keys(phaseVals).map<IDropdownMenuItem>(key => {
            return {
                title: phaseVals[key],
                value: phaseKeys[key]
            }
        })

        setPhaseItems(menuItems);
        setCurrentPhase(study.phase);

        const recruitmentVals = Object.values(RecruitmentStatusEnum);
        const recruitmentKeys = Object.keys(RecruitmentStatusEnum);
        const recruitmentMenuItems = Object.keys(recruitmentVals).map<IDropdownMenuItem>(key => {
            return {
                title: recruitmentVals[key],
                value: recruitmentKeys[key]
            }
        })

        setRecruitmentStatusItems(recruitmentMenuItems);
        setCurrentRecruitmentStatus(study.overallStatus);

    }, [study]);

    return (
        <div style={topStyle}>
            <div style={Styles.FORM_ROW}>
                <DropdownFormFieldComponent
                    name={`phase`}
                    label={"Phase"}
                    items={phaseItems}
                    style={threeAcrossStyle}
                    objectKey={'name'}
                    value={currentPhase}
                />
                <TextFormFieldComponent
                    name='extractedConditions'
                    label='Condition or Disease'
                    style={twoThirdsStyle}
                    readonly={true}
                    value={extractedConditions}
                />

            </div>
            <div style={Styles.FORM_ROW}>
                <TextFormFieldComponent
                    name='extractedInterventions'
                    label='Intervention/Treatment'
                    style={oneAcrossStyle}
                    readonly={true}
                    value={extractedInterventions}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <TextAreaFormFieldComponent
                    name='extractedBriefSummary'
                    label='Brief Summary From Registry (if available)'
                    style={oneAcrossStyle}
                    readonly={userIsReadOnly}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <TextFormFieldComponent
                    name='participantEligibility.ageDescription'
                    label='Ages Eligible for Study'
                    style={fourAcrossStyle}
                    readonly={userIsReadOnly}
                />
                <TextFormFieldComponent
                    name='participantEligibility.sex'
                    label='Sexes Eligible for Study'
                    style={fourAcrossStyle}
                    readonly={userIsReadOnly}
                />
                <TextFormFieldComponent
                    name='acceptsHealthyVolunteers'
                    label='Accepts Healthy Volunteers'
                    style={fourAcrossStyle}
                    readonly={userIsReadOnly}
                />
                <TextFormFieldComponent
                    name='actualEnrollment'
                    label='Actual Enrollment'
                    style={fourAcrossStyle}
                    readonly={userIsReadOnly}
                />
            </div>
            {study.locationsOfStudySites && study.locationsOfStudySites.length > 0 &&
                <div style={Styles.FORM_ROW}>
                    <TextAreaFormFieldComponent
                        name={'locationsOfStudySites'}
                        label={'Locations of Study Sites'}
                        style={oneAcrossStyle}
                        value={locations}
                        readonly={true}
                        rows={3}
                    />
                </div>
            }
            <div style={Styles.FORM_ROW}>
                <TextAreaFormFieldComponent
                    name='additionalInformation'
                    label='Additional Information'
                    style={oneAcrossStyle}
                    readonly={false}
                    rows={3}
                />
            </div>
            <FieldHeaderComponent title={'STUDY HISTORY'}/>
            <div style={Styles.FORM_ROW}>
                <DatePickerFormFieldComponent
                    name='startDate'
                    label='Study Start Date'
                    style={threeAcrossStyle}
                    readonly={userIsReadOnly}
                />
                <DatePickerFormFieldComponent
                    name='actualStudyCompletionDate'
                    label='Actual Study Completion Date'
                    style={threeAcrossStyle}
                    readonly={userIsReadOnly}
                />
                <DropdownFormFieldComponent
                    name='overallStatus'
                    label='Overall Status'
                    items={recruitmentStatusItems}
                    style={threeAcrossStyle}
                    value={currentRecruitmentStatus}
                    objectKey={'name'}
                    readonly={userIsReadOnly}
                />
            </div>
            <div>
                <PicoComponent study={study} />
            </div>
            {user.isVivliAdmin &&
                <div>
                    <FieldHeaderComponent title={'PICO AND STUDY DESIGN'}/>
                    <div style={Styles.FORM_ROW}>
                        <div style={{...oneAcrossStyle, boxShadow: 'none'}}>
                            <StudyDatasetJsonComponent
                                json={picoAndStudyDesignJsonString}
                                showTitle={false}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
