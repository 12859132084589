import React, {CSSProperties, useEffect, useState} from 'react';
import {useActiveUser} from '@vivli/core/infrastructure/context'
import {IUser} from "@vivli/shared/infrastructure/interface";
import {AdminConsoleComponent} from "@vivli/features/admin-console/components";
import {IAdminConsoleTab} from "@vivli/features/admin-console/infrastructure/interface";
import {Color, Size} from "@vivli/shared/theme";
import {AdminHeaderComponent, AdminHeaderTitleComponent} from "@vivli/shared/components";
import {useAppEnvironment} from "@vivli/core/infrastructure/hook";
import {
    AdminConsoleContextWrapper, AdminConsoleLookupIDContextWrapper,
} from "@vivli/features/admin-console/infrastructure/wrappers";
import {useNavigate} from "react-router";

const textContainerStyle: CSSProperties = {
    marginLeft: Size.INSET_SIZE,
    fontSize: Size.FontSize.Larger,
    color: Color.DARK_GRAY,
    marginTop: 20,
}
const containerHeaderStyle: CSSProperties = {
    display: "flex",
    paddingRight: 40,
    justifyContent: "space-between",
}

const containerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%'
}

enum TabTitleEnum {
    templateStudy = 'Template Study',
    dataRequest = 'Data Request',
    virtualMachine = 'Virtual Machine',
    configSettings = 'Config Settings(later)',
    auditTrail = 'Audit Trail',
}

enum TabRouteEnum {
    templateStudy = 'templateStudy',
    dataRequest = 'dataRequest',
    virtualMachine = 'virtualMachine',
    configSettings = 'configSettings',
    auditTrail = 'auditTrail',
}

const getTabs = (user: IUser) => {
    const defaultTabs: IAdminConsoleTab[] = [
        {
            name: TabRouteEnum.templateStudy,
            sortOrder: 0,
            title: TabTitleEnum.templateStudy,
        }, {
            name: TabRouteEnum.dataRequest,
            sortOrder: 1,
            title: TabTitleEnum.dataRequest,
        }, {
            name: TabRouteEnum.virtualMachine,
            sortOrder: 2,
            title: TabTitleEnum.virtualMachine,
        }, {
            name: TabRouteEnum.configSettings,
            sortOrder: 3,
            title: TabTitleEnum.configSettings,
        }, {
            name: TabRouteEnum.auditTrail,
            sortOrder: 4,
            title: TabTitleEnum.auditTrail,
        }
    ]

    return defaultTabs.sort((a, b) => a.sortOrder - b.sortOrder);
}

export const AdminConsoleRequestsFeature = () => {
    const [tabs, setTabs] = useState<IAdminConsoleTab[]>();
    const user = useActiveUser();
    const navigate = useNavigate();
    const appEnvironment = useAppEnvironment();

    const handleTabClick = (tab: IAdminConsoleTab) => {
        navigate(`/admin/adminConsole/${tab.name}`);
    }

    useEffect(() => {
        setTabs(getTabs(user));
    }, [])


    return <div style={containerStyle}>
        <AdminConsoleContextWrapper>
            <AdminConsoleLookupIDContextWrapper>
                <>
                <div style={containerHeaderStyle}>
                    <AdminHeaderComponent>
                        <AdminHeaderTitleComponent title={`Operations Admin Portal`}/>
                    </AdminHeaderComponent>
                    <div style={textContainerStyle}> Environment: {appEnvironment.activeEnv}</div>
                </div>
                <AdminConsoleComponent
                    onTabClick={handleTabClick}
                    tabs={tabs}
                />
                </>
            </AdminConsoleLookupIDContextWrapper>
        </AdminConsoleContextWrapper>
    </div>
}

