import React from "react";
import {useTranslation} from "react-i18next";

export const DefaultBasicDataPackageListComponent = () => {
    const {t} = useTranslation();
    return (
        <ul>
            <li>{t("IPD")}</li>
            <li>Protocol with Amendments</li>
            <li>Statistical Analysis Plan</li>
            <li>Data Dictionary</li>
        </ul>
    )
}
