import {CSSProperties} from "react";

export const searchBarContainerStyle: CSSProperties = {
    minHeight: '70px',
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    alignItems: 'center'
}

export const searchFiltersContainerStyle: CSSProperties = {
    overflow: 'hidden',
    width: '100%'
}

export const searchFiltersVerticalContainerStyle: CSSProperties = {
    padding: '0 15px',
    display: 'flex',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto'
}

export const filterGroupStyle: CSSProperties = {
    display: 'flex',
    flexGrow: 1,
    padding: '10px',
    height: '100%'
}

export const filterGroupBorderStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
}

export const inputGroupBreakStyle: CSSProperties = {
    paddingBottom: '15px'
}

export const amrFieldTitleStyle: CSSProperties = {
    fontSize: '16px'
}

export const filterGroupContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white'
}

export const filterGroupVerticalContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    borderRadius: '10px',
    backgroundColor: 'white',
    marginBottom: '10px'
}

export const advancedGridStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridTemplateRows: 'auto',
    columnGap: '10px',
    padding: '10px',
}

export const innerScrollStyle: CSSProperties = {
    ...advancedGridStyle,
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundImage: 'linear-gradient(135deg, rgba(146, 172, 187, 0.65) 0%, rgb(146, 172, 187) 90%)'
}

export const innerScrollVerticalStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: '1fr 1fr 1fr 1fr',
    height: '100%',
}
