﻿import React from 'react';
import { Color } from '@vivli/shared/theme';
import { ButtonComponent } from '@vivli/shared/components';

interface DataPackageValidateButtonProps {
    onClick: () => void;
    style?: React.CSSProperties;
    title?: string;
}

const buttonContainerStyle: React.CSSProperties = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '130px',
};
const buttonStyle: React.CSSProperties = {
    background: 'transparent',
    color: Color.VIVLI_BLUE,
    boxShadow: 'none',
    minWidth: '60px',
    paddingLeft: '0px',
};


export const DataPackageValidateButtonComponent = (props: DataPackageValidateButtonProps) => {
    const { onClick, style, title } = props;

    return (
        <div>
            <ButtonComponent
                onClick={onClick} title={title || '"Verify Upload" will confirm that for each of the files listed below, the file itself has been fully uploaded and is stored in the system.'}
            >
                Verify Upload
            </ButtonComponent>
        </div>
    );
};
