import { CSSProperties } from 'react';
import { StudiesProvidedComponent } from '../studies-provided.component';
import { ResearchHelpLinkComponent } from '../research-help-link.component';
import { VmStatusEnum } from '@vivli/features/virtual-machine/infrastructure/enum';
import { VmProvisionedButtonsComponent } from './vm-provisioned-buttons.component';
import { VmProvisionedConnectComponent } from './vm-provisioned-connect.component';
import { Color, Size, Styles } from '@vivli/shared/theme';
import { useResearchEnvironmentContext } from '@vivli/features/data-requests/infrastructure/context';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const containerStyle: CSSProperties = {
    display: 'flex',
    alignSelf: 'center',
    justifySelf: 'center',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'column',
};

const innerContainerStyle: CSSProperties = {
    ...Styles.CENTERED_FLEX,
    flexDirection: 'column',
    marginBottom: Size.INSET_SIZE,
};

const imageContainerStyle: CSSProperties = {
    marginTop: Size.PADDING,
};

const controlsContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
};

export const VmProvisionedViewComponent = () => {
    const { provisionStatus, authorizedUser } = useResearchEnvironmentContext();

    const isVmRunning = provisionStatus === VmStatusEnum.Running;

    const userNameContainerStyle: CSSProperties = {
        fontSize: Size.FontSize.XLarge,
        fontWeight: Size.FontWeight.Bold,
        backgroundSize: 100,
        position: 'relative',
        marginBottom: 30,
        color: isVmRunning ? Color.VIVLI_TEAL : Color.LIGHTER_GRAY,
    };

    return (
        <div style={containerStyle}>
            <div>
                <StudiesProvidedComponent />
            </div>

            <ResearchHelpLinkComponent isNewVm={false} />

            <div style={innerContainerStyle}>
                <div style={imageContainerStyle}>
                    <img
                        src={
                            isVmRunning
                                ? AssetsConstant.VM_RUNNING
                                : AssetsConstant.VM_NOT_RUNNING
                        }
                    />
                </div>

                <div style={userNameContainerStyle}>
                    {isVmRunning && (
                        <img
                            style={{ marginRight: 15 }}
                            src={AssetsConstant.VM_CHECK_MARK}
                            height={27}
                        />
                    )}
                    Research Environment {provisionStatus}
                </div>

                <div style={controlsContainerStyle}>
                    {isVmRunning && <VmProvisionedConnectComponent />}

                    <VmProvisionedButtonsComponent />
                </div>
            </div>
        </div>
    );
};
