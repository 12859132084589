﻿import React, { CSSProperties, useState } from 'react';
import { outerTabStyle, tabContentContainerStyle } from '../shared/styles';
import {
    FieldHeaderComponent,
    HintPopoverComponent,
} from '@vivli/shared/components';
import { useListingRequestContext } from '@vivli/features/listing-request/infrastructure/context';
import { ListingRequestStatusEnum } from '@vivli/features/listing-request/infrastructure/enum';
import { StudyIpdDataPackageFeature } from '@vivli/shared/features/data-package/feature';
import { dataPackageHint } from '@vivli/features/listing-request/infrastructure/constants';
import { Color, Size } from '@vivli/shared/theme';
import { IDataPackage } from '@vivli/shared/features/data-package/infrastructure/interface';
import { DataPackageStatusEnum } from '@vivli/features/studies/infrastructure/enum';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const uploadButtonStyle: CSSProperties = {
    width: '40%',
    marginLeft: 10,
};

export const UploadDataComponent = () => {
    const { listingRequest } = useListingRequestContext();
    const [isPackageSubmitted, setisPackageSubmitted] = useState(false);

    const isDisabled =
        listingRequest?.status !== ListingRequestStatusEnum.Approved;
    const isNotAvailable =
        listingRequest?.status === ListingRequestStatusEnum.Draft ||
        listingRequest?.status === ListingRequestStatusEnum.Withdrawn;
    const notAvailableText =
        'You will be able to upload data when your request has been submitted, accepted, and the Data Contribution Agreement has been executed.';
    const disabledText =
        'Your request is being reviewed. You will be able to upload the data when it has been accepted and the Data Contribution Agreement has been executed.';
    const enabledInfoText =
        'Your request has been accepted and the Data Contribution Agreement has been executed - your study is available for requesting. Please upload the data below.';
    const uploadedInfoText =
        'Your request has been accepted, the Data Contribution Agreement has been executed, your study is available for requesting and the data has been uploaded.';
    const disabledInfoText = isNotAvailable ? notAvailableText : disabledText;
    const infoText = isDisabled
        ? disabledInfoText
        : isPackageSubmitted
        ? uploadedInfoText
        : enabledInfoText;

    const uploadDivStyle: CSSProperties = {
        display: 'grid',
        gridTemplateColumns: '50% 1fr',
        marginBottom: isPackageSubmitted ? 0 : 30,
    };

    const onPackageAvailable = (dataPackage: IDataPackage) => {
        var isSubmitted =
            dataPackage?.status === DataPackageStatusEnum.Submitted;
        setisPackageSubmitted(isSubmitted);
    };

    return (
        <div style={outerTabStyle} className={'scrolly'}>
            <div style={tabContentContainerStyle}>
                <FieldHeaderComponent
                    title={
                        isPackageSubmitted
                            ? 'DATA PACKAGE UPLOAD COMPLETE'
                            : 'UPLOAD THE STUDY DATA'
                    }
                />
                <div style={uploadDivStyle}>
                    <div>
                        {infoText}
                        {!isPackageSubmitted && (
                            <div>
                                <p>
                                    Study data packages must include at least 4
                                    file types - click here for more
                                    information.
                                    <HintPopoverComponent
                                        position={'top'}
                                        hintText={dataPackageHint}
                                        maxWidth={Size.POPOVER_MAX_WIDTH}
                                        popoverColor={Color.TEXT_LIGHT_BLUE}
                                        popoverArrowColor={
                                            Color.TEXT_LIGHT_BLUE
                                        }
                                        icon={AssetsConstant.INFO_ICON}
                                    />
                                </p>
                                <p>
                                    If any of these files are not available,
                                    please include a placeholder file stating
                                    that it is not available. When you are ready
                                    to upload data to the Vivli Platform, if the
                                    anonymized Individual Participant-level Data
                                    are held in several files, we recommend that
                                    you zip them into a single Data file. We
                                    recommend that you load other accompanying
                                    documents as separate files.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                {!isDisabled && (
                    <div>
                        <StudyIpdDataPackageFeature
                            dataPackageId={
                                listingRequest?.datasets[0]?.ipdDataPackageId
                            }
                            ipdContentType={
                                listingRequest?.datasets[0]?.ipdContentType
                            }
                            studyId={listingRequest?.datasets[0]?.id}
                            emailOnSuccess={listingRequest}
                            onPackageAvailable={onPackageAvailable}
                            onPackageSubmitted={onPackageAvailable}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
