import React, {createContext, ReactNode, useEffect, useState} from 'react';
import {IDataPackage} from "@vivli/shared/features/data-package/infrastructure/interface";
import {useDataPackageService} from "@vivli/shared/features/data-package/infrastructure/context";
import {first} from "rxjs/operators";
import {DataPackageLoaderComponent} from "./data-package-loader.component";

interface DataPackageBaseComponentProps {
    dataPackageId: string;
    onPackageAvailable?: (dataPackage: IDataPackage) => void;
    children: ReactNode;
}

export const DataPackageContext = createContext<IDataPackage>(null);

export const DataPackageBaseComponent = (
    {
        dataPackageId,
        onPackageAvailable,
        children
    }: DataPackageBaseComponentProps) => {
    const [dataPackage, setDataPackage] = useState<IDataPackage>();

    const dataPackageService = useDataPackageService();

    useEffect(() => {
        if (!dataPackageId) { return; }

        const sub = dataPackageService.getDataPackage(dataPackageId)
            .pipe(first())
            .subscribe((dataPackage)=> {
                setDataPackage(dataPackage);
                if (onPackageAvailable){
                    onPackageAvailable(dataPackage);
                }
            });

        return () => {
            sub.unsubscribe();
        }
    }, [dataPackageId])

    if (!dataPackage) {
        return <DataPackageLoaderComponent />;
    }

    return <DataPackageContext.Provider value={dataPackage}>
        {children}
    </DataPackageContext.Provider>
}
