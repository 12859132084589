export const StudyAvailableIpdFileTypesConstant = [
    'Unknown',
    'IPD',
    'Data Dictionary',
    'Protocol',
    'Statistical Analysis Plan',
    'Analysis-Ready Dataset',
    'CSR (may be redacted)',
    'Analysis-ready Dataset',
    'Annotated Case Report Form',
    'Other'
]
