import { IUserDetails } from '@vivli/features/users/infrastructure/interface';
import moment from 'moment';
import { IReportItem } from '@vivli/shared/infrastructure/interface';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { StudyStatusEnum } from '@vivli/features/studies/infrastructure/enum';
import { v4 as uuid } from 'uuid';
import { IListingRequestSummary } from '@vivli/features/listing-request/infrastructure/interface';

export abstract class DataTransformationService {
    public static formatUserDetails = (user: IUserDetails): IUserDetails => {
        if (!user) {
            return user;
        }

        let roleName = null;

        if (user.isVivliAdmin) {
            roleName = '(Vivli Admin)';
        } else if (user.isOperationsAdmin) {
            roleName = '(Operations Admin)';
        }

        return {
            ...user,
            status: user.disabled ? 'Disabled' : 'Active',
            roleName,
            displayNameWithAdminStatus: roleName ? user.displayName + ' ' + roleName : user.displayName,
        };
    };

    public static formatUsersDetails = (users: IUserDetails[]): IUserDetails[] => {
        return users?.map(DataTransformationService.formatUserDetails);
    };

    public static formatDate = (date: Date | string): string => {
        return date ? moment(date).format('YYYY-MM-DD') : '';
    };

    public static formatReportItems = (items: any[]): IReportItem[] => {
        return items.map((reportItem) => {
            const firstPublicationDate = DataTransformationService.formatDate(reportItem.statusUpdate?.firstPublicationDate);
            const dateModified = DataTransformationService.formatDate(reportItem.dateModified);
            const dateSubmittedToDataContributor = DataTransformationService.formatDate(reportItem.dateSubmittedToDataContributor);
            const nextActionDate = DataTransformationService.formatDate(reportItem.statusUpdate?.nextActionDate);
            const nextActionDateLR = DataTransformationService.formatDate(reportItem.nextActionDate);
            const currentWorkflowStepStartDate = DataTransformationService.formatDate(reportItem.statusUpdate?.currentWorkflowStepStartDate);
            const vmBillingStartDate = DataTransformationService.formatDate(reportItem.statusUpdate?.vmBillingStartDate);
            const submittedDate = DataTransformationService.formatDate(reportItem.submittedDate);
            const postedDate = DataTransformationService.formatDate(reportItem.postedDate);
            const dateSubmitted = DataTransformationService.formatDate(reportItem.dateSubmitted);
            const studyFinalCompletionDate = DataTransformationService.formatDate(reportItem.studyFinalCompletionDate);
            const dateFinalResponse = DataTransformationService.formatDate(reportItem.dateFinalResponse);
            const lastUpdated = DataTransformationService.formatDate(reportItem.lastUpdated);

            let realId = reportItem.id; //this is the real id of the data request
            if (reportItem.enquiryId) {
                realId = reportItem.enquiryId;
            }

            return {
                ...reportItem,
                submittedDate,
                postedDate,
                dateSubmitted,
                studyFinalCompletionDate,
                dateFinalResponse,
                nextActionDate: nextActionDateLR,
                dateModified,
                lastUpdated,
                realId: realId,
                id: uuid(), //guid used to guarantee we have a unique id in all cases
                dataContributorsList: reportItem.dataContributors?.split(';').filter((x) => x?.length > 0),
                resultsRemovalRequestsList: reportItem.resultsRemovalRequests?.split(';').filter((x) => x?.length > 0),
                statusUpdate: {
                    ...reportItem.statusUpdate,
                    firstPublicationDate,
                    nextActionDate,
                    currentWorkflowStepStartDate,
                    vmBillingStartDate,
                    requestReviewStatusList: reportItem.statusUpdate?.requestReviewStatus.split(';').filter((x) => x?.length > 0),
                    dataUploadStatusList: reportItem.statusUpdate?.dataUploadStatus.split(';').filter((x) => x?.length > 0),
                    duaStatusList: reportItem.statusUpdate?.duaStatus.split(';').filter((x) => x?.length > 0),
                },
                dateSubmittedToDataContributor,
            };
        });
    };

    public static mapStatusToMessage(status: StudyStatusEnum): string {
        switch (status) {
            case StudyStatusEnum.Draft:
                return 'Draft';
            case StudyStatusEnum.SubmittedToVivliForPreCheck:
                return 'Awaiting Vivli Review';
            case StudyStatusEnum.AwaitingCuration:
                return 'Awaiting Curation';
            case StudyStatusEnum.CurationInProgress:
                return 'Curation In Progress';
            case StudyStatusEnum.CurationInternalReview:
                return 'Curation Internal Curator Review';
            case StudyStatusEnum.AwaitingCurationQaReview:
                return 'Awaiting Curation QC Review';
            case StudyStatusEnum.CurationQaReviewInProgress:
                return 'Curation QC Review In Progress';
            case StudyStatusEnum.CurationRevisionsRequired:
                return 'Curation Revisions Requested';
            case StudyStatusEnum.CurationDiscussionsRequired:
                return 'Curation Requires Revisions from Curator';
            case StudyStatusEnum.CurationBlocked:
                return 'Curation Blocked Due to Issues';
            case StudyStatusEnum.SubmittedToVivliForPostCheck:
                return 'Awaiting Vivli Post Review';
            case StudyStatusEnum.SponsorApprovesCuration:
                return 'Study Sponsor Has Approved Curation';
            case StudyStatusEnum.SponsorRequiresCurationRevisions:
                return 'Study Sponsor Requested Curation Changes';
            case StudyStatusEnum.Posted:
                return 'Posted';
            case StudyStatusEnum.Cancelled:
                return 'Submission Cancelled';
        }

        return status;
    }

    public static getStudyDateDisplay = (study: IStudy) => {
        switch (study.status) {
            case StudyStatusEnum.Draft:
                return DataTransformationService.formatDate(study.draftCreatedDate);
                break;
            case StudyStatusEnum.Posted:
            case StudyStatusEnum.Cancelled:
                return DataTransformationService.formatDate(study.postedDate);
                break;
            default:
                return DataTransformationService.formatDate(study.submittedDate);
        }
    };

    public static formatStudy = (study: IStudy) => {
        return {
            ...study,
            studyStatusDisplay: DataTransformationService.mapStatusToMessage(study.status),
            dateDisplay: DataTransformationService.getStudyDateDisplay(study),
        };
    };

    public static formatStudies = (studies: IStudy[]) => {
        return studies.map(DataTransformationService.formatStudy);
    };

    public static formatListingRequestSummary = (listingRequestSummary: IListingRequestSummary) => {
        return {
            ...listingRequestSummary,
            createdDateDisplay: DataTransformationService.formatDate(listingRequestSummary.createdDate),
            submittedDateDisplay: DataTransformationService.formatDate(listingRequestSummary.submittedDate),
            approvedDateDisplay: DataTransformationService.formatDate(listingRequestSummary.approvedDate),
            withdrawnDateDisplay: DataTransformationService.formatDate(listingRequestSummary.withdrawnDate),
        };
    };

    public static formatListingRequestSummaries = (listingRequestSummaries: IListingRequestSummary[]) => {
        return listingRequestSummaries.map(DataTransformationService.formatListingRequestSummary);
    };
}
