import Guacamole from 'guacamole-common-js';
import {CSSProperties, useEffect, useRef, useState} from 'react';
import {useVirtualMachineService} from '@vivli/features/virtual-machine/infrastructure/context';
import {debounceTime, first} from 'rxjs/operators';
import {useParams} from 'react-router';
import {ButtonComponent, LoadIndicatorCenteredComponent} from '@vivli/shared/components';
import {useModalService} from '@vivli/shared/infrastructure/context';
import {useActiveUser, useConfigService} from '@vivli/core/infrastructure/context';
import {Subject} from 'rxjs';
import {useAuthService} from '@vivli/core/authentication';
import {useTabFocusHook} from '@vivli/shared/infrastructure/hook';

const getBoundingRect = () => document.body.getBoundingClientRect();

const containerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
};

const displayStyle: CSSProperties = {
    ...containerStyle,
    cursor: 'none',
};

const messageContainerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    cursor: 'default',
    backgroundColor: 'white',
    zIndex: 1,
};

const messageStyle: CSSProperties = {
    display: 'grid',
    rowGap: '15px',
};

enum GuacStateEnum {
    Idle,
    Connecting,
    Waiting,
    Connected,
    Disconnecting,
    Disconnected,
}

export const VirtualMachinePortalFeature = () => {
    const { virtualMachineId, dataRequestDoi } = useParams();
    const virtualMachineService = useVirtualMachineService();
    const modalService = useModalService();
    const configService = useConfigService();
    const activeUser = useActiveUser();
    const authService = useAuthService();
    const timeoutRef = useRef<any>();
    const handleTabFocus = useTabFocusHook();

    const resizeSubjectRef = useRef(new Subject());
    const activitySubjectRef = useRef(new Subject());
    const guacClientRef = useRef<Guacamole.Client>(null);
    const guacStateRef = useRef<number>(GuacStateEnum.Disconnected);
    const displayRef = useRef<HTMLDivElement>(null);
    const activeModalRef = useRef<string>(null);
    const activeGuacDisplayRef = useRef<HTMLElement>();

    const [disconnectedMsg, setDisconnectedMsg] = useState<string>(null);

    document.title = `Vivli - ${dataRequestDoi}`;

    const showOverlayMessage = (message: string) => {
        activeModalRef.current = modalService.custom(<LoadIndicatorCenteredComponent message={message} />, {
            fullScreen: true,
        });
    };

    const createGuacClient = () => {
        guacClientRef.current = new Guacamole.Client(new Guacamole.WebSocketTunnel(configService.guacUrl));
    };

    const handleKeyBinding = () => {
        const keyboard = new Guacamole.Keyboard(document.body);

        const fixKeys = (keysym) => {
            // 65508 - Right Ctrl
            // 65507 - Left Ctrl
            // somehow Right Ctrl is not sent, so send Left Ctrl instead
            if (keysym === 65508) return 65507;
            return keysym;
        };

        keyboard.onkeydown = function (keysym) {
            if (guacStateRef.current !== GuacStateEnum.Connected) {
                return;
            }

            guacClientRef.current.sendKeyEvent(1, fixKeys(keysym));
        };
        keyboard.onkeyup = function (keysym) {
            if (guacStateRef.current !== GuacStateEnum.Connected) {
                return;
            }

            guacClientRef.current.sendKeyEvent(0, fixKeys(keysym));
            activitySubjectRef.current.next();
        };

        // Mouse
        const mouse = new Guacamole.Mouse(document.body);
        mouse.onmousemove = function (mouseState) {
            if (guacStateRef.current !== GuacStateEnum.Connected) {
                return;
            }

            guacClientRef.current.sendMouseState(mouseState);
            activitySubjectRef.current.next();
        };
        mouse.onmousedown = mouse.onmouseup = function (mouseState) {
            if (guacStateRef.current !== GuacStateEnum.Connected) {
                return;
            }

            guacClientRef.current.sendMouseState(mouseState);
            activitySubjectRef.current.next();
        };
    };

    const parentOnClickHandler = () => {
        displayRef.current.focus();
    };

    const getGuacToken = () => {
        if (activeUser.isVivliAdmin) {
            return virtualMachineService.getAdminGuacToken(virtualMachineId);
        }

        return virtualMachineService.getGuacToken(virtualMachineId);
    };

    const handleConnectionTimeout = () => {
        if (guacStateRef.current === GuacStateEnum.Connected || guacStateRef.current === GuacStateEnum.Connecting) {
            return;
        }

        setTimeout(() => {
            handleRetryConnection();
        }, 15000);
    };

    const handleGuacConnect = () => {
        if (guacStateRef.current === GuacStateEnum.Disconnected) {
            getGuacToken()
                .pipe(first())
                .subscribe((token) => {
                    const boundingRect = getBoundingRect();
                    const dpi = window.devicePixelRatio * 96 || 96;
                    guacClientRef.current.connect('token=' + token + '&width=' + boundingRect.width + '&height=' + boundingRect.height + '&dpi=' + dpi);
                });

            // don't run set timeout if we're connecting to the service
            return;
        }

        setTimeout(() => {
            handleGuacConnect();
        }, 500);
    };

    const handleGuacConnected = () => {
        setDisconnectedMsg(null);

        activeGuacDisplayRef.current = guacClientRef.current.getDisplay().getElement();

        displayRef.current.appendChild(activeGuacDisplayRef.current);

        // wait to allow login to happen behind loading screen
        setTimeout(() => {
            modalService.dismiss(activeModalRef.current);
            activeModalRef.current = null;
        }, 2000);
    };

    const handleGuacStateChange = () => {
        guacClientRef.current.onstatechange = (newState: GuacStateEnum) => {
            const previousState = guacStateRef.current;

            switch (newState) {
                case GuacStateEnum.Waiting:
                    // only execute the following logic if we've moved from connecting to waiting
                    if (previousState === GuacStateEnum.Connecting) {
                        handleConnectionTimeout();
                    }
                    break;
                case GuacStateEnum.Connected:
                    handleGuacConnected();
                    handleKeyBinding();
                    break;
                case GuacStateEnum.Disconnected:
                    if (activeGuacDisplayRef.current) {
                        displayRef.current.removeChild(activeGuacDisplayRef.current);
                    }
                    setDisconnectedMsg('You have been disconnected, please click the button below to reconnect.');
                    break;
            }

            guacStateRef.current = newState;
        };
    };

    const handleRetryConnection = () => {
        setDisconnectedMsg('Unable to connect, please click the button below to try again.');
        modalService.dismiss();
    };

    const handleGuacInit = () => {
        createGuacClient();
        handleGuacStateChange();
        handleGuacConnect();
    };

    const handleDisconnect = () => {
        if (guacStateRef.current !== GuacStateEnum.Disconnected && guacStateRef.current !== GuacStateEnum.Disconnecting) {
            guacClientRef.current.disconnect();
        }
    }

    const handleGuacReconnect = () => {
        handleDisconnect();
        handleGuacInit();
    };

    const handleReconnect = () => {
        showOverlayMessage('Reconnecting...');
        handleGuacReconnect();
    };

    const handleResizeDebounce = () => {
        resizeSubjectRef.current.next();
    };

    const handleWindowResize = () => {
        window.addEventListener('resize', handleResizeDebounce);
    };

    const handleTokenRefresh = () => {
        authService.getTokenPopup().then(() => {
            // no action necessary, this will refresh the users session to keep it alive
        });
    };

    const onTabFocus = () => {
        if (timeoutRef.current) {
            // if the timeout still exists, we are still connected to guac
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        } else {
            // 5m has elapsed, we are currently disconnected
            // automatically reconnect now that the tab is visible
            handleReconnect();
        }
    }

    const onTabBlur = () => {
        const timeoutInMs = configService.guacTimeoutMinutes * 60000;
        timeoutRef.current = setTimeout(() => {
            handleDisconnect();
            timeoutRef.current = null;
        }, timeoutInMs) // 5m timeout
    }

    useEffect(() => {
        showOverlayMessage('Connecting...');

        handleTabFocus({
            onTabFocus,
            onTabBlur
        })
    }, []);

    useEffect(() => {
        if (!configService) {
            return;
        }

        handleGuacInit();
        handleWindowResize();

        const resizeSub = resizeSubjectRef.current.pipe(debounceTime(500)).subscribe(() => {
            const boundingRect = getBoundingRect();
            guacClientRef.current.sendSize(boundingRect.width, boundingRect.height);
        });

        const activitySub = activitySubjectRef.current.pipe(debounceTime(1000)).subscribe(() => {
            handleTokenRefresh();
        });

        return () => {
            window.removeEventListener('resize', handleResizeDebounce);
            resizeSub.unsubscribe();
            activitySub.unsubscribe();
            guacClientRef.current.disconnect();
        };
    }, [configService]);

    return (
        <div style={containerStyle}>
            {disconnectedMsg && (
                <div style={messageContainerStyle}>
                    <div style={messageStyle}>
                        <div>{disconnectedMsg}</div>
                        <div>
                            <ButtonComponent onClick={handleReconnect}>Reconnect</ButtonComponent>
                        </div>
                    </div>
                </div>
            )}
            <div ref={displayRef} id="display" onClick={parentOnClickHandler} style={displayStyle} />
        </div>
    );
};
