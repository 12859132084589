import React from 'react';
import { ButtonComponent } from '@vivli/shared/components';
import { buttonIconStyle, buttonStyle } from './button.styles';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

export const SearchButtonComponent = ({ onClick }) => (
    <ButtonComponent
        // disabled={!canExecuteSearch}
        style={buttonStyle}
        onClick={onClick}
        className="search_go"
        data-test-id={'search_go'}
    >
        <img
            src={AssetsConstant.WHITE_MAGNIFYING_GLASS}
            style={buttonIconStyle}
        />
    </ButtonComponent>
);
