import React, {CSSProperties, useEffect, useRef, useState} from "react";
import {
    btnStyle, ButtonComponent, GridComponent, TextAreaComponent, TextFieldComponent,
    TextFormFieldComponent
} from "@vivli/shared/components";
import {Size} from "@vivli/shared/theme";
import {ColDef} from "ag-grid-community";
import './enquiry.style.scss';
import {IDataInfoRequest} from "@vivli/features/enquiry/infastructure/interface";
import {useEnquiryContext} from "@vivli/features/enquiry/infastructure/context";
import {GridCellRendererEnum} from "@vivli/shared/infrastructure/enum";
import {GridApi} from 'ag-grid-community';
import {useActiveUser} from "@vivli/core/infrastructure/context";

const twoColumnGrid: CSSProperties = {
    display: 'grid',
    justifyContent: 'space-around',
    gridTemplateColumns: '2fr 1fr',
    columnGap: '25px',
}
const paddingTop20: CSSProperties = {
    paddingTop: '20px',
}
const discussionGridStytle: CSSProperties = {
    height: '300px',
}
const paragraphStyle: CSSProperties = {
    lineHeight: 1.2,
    wordBreak: 'break-word',
    margin: 0,
    marginBottom: '3px',
};


interface EnquiryCommentComponentProps {
    dataInfoRequest: IDataInfoRequest;
    dirIndex: number;
}

export const EnquiryDiscussionGridComponent = ({dataInfoRequest, dirIndex}: EnquiryCommentComponentProps) => {
    const [discussionComment, setDiscussionComment] = useState('');
    const [discussionRows, setDiscussionRows] = useState(dataInfoRequest?.comments || []);
    const {addComment, enquiry} = useEnquiryContext();
    const gridApiRef = useRef<GridApi>();
    const user = useActiveUser()
    const columns: ColDef[] = [
        {
            field: 'createdDate',
            headerName: '',
            filter: false,
            sortable: false,
            cellRenderer: GridCellRendererEnum.GridCellDateTime,
            minWidth: 180,
            autoHeight: true,
            tooltipField: 'createdDate',
        },
        {
            field: 'displayName',
            headerName: '',
            filter: false,
            sortable: false,
            minWidth: 150,
            autoHeight: true,
            tooltipField: 'displayName',
        },
        {
            field: 'comment',
            headerName: '',
            filter: false,
            sortable: false,
            minWidth: 300,
            flex: 1,
            suppressSizeToFit: true,
            resizable: false,
            wrapText: true,
            autoHeight: true,
            tooltipField: 'comment',
            cellStyle: {...paragraphStyle},
        }
    ];

    const handleAddComment = (e) => {
        e.stopPropagation();
        if (discussionComment) {
            addComment(dataInfoRequest,  dirIndex, discussionComment);
            setDiscussionRows([...dataInfoRequest?.comments]);
            setDiscussionComment('');
            scrollToBottom(gridApiRef.current, true);

        }
    }

    useEffect(() => {
        //will not display 1st added comment without this code
        if(dataInfoRequest?.comments){setDiscussionRows([...dataInfoRequest?.comments]);}
    }, [dataInfoRequest?.comments]);

    const handleOnGridReady = (gridApi: GridApi) => {
        gridApiRef.current = gridApi;
        scrollToBottom(gridApi, false);
    };
    const scrollToBottom = (gridApi: GridApi, isAddition: boolean) => {
        setTimeout(() => {
            if (discussionRows?.length > 0) {
                //true - on add comment - discussion rows are not yet set :-(
                var numberOfRows = isAddition ? discussionRows?.length : discussionRows?.length - 1;
                setTimeout(() => gridApiRef.current.ensureIndexVisible(numberOfRows, 'bottom'), 100);
            }
        }, 50);
    }

    // @ts-ignore
    return (
        <div>
            <div  style = {{paddingBottom: '15px'}}>Discussion:</div>
            <div className='enquiryDiscussionHeader'>
                <GridComponent
                    onGridReady={handleOnGridReady}
                    onRowCountChange={(count) => scrollToBottom(gridApiRef.current, true)}
                    rowData={discussionRows} columns={columns}
                    rowHeight={300}
                    hideHeader={true} style={discussionGridStytle}/>
            </div>
            <div style={{...twoColumnGrid, ...paddingTop20}}>
                <TextAreaComponent
                    rows={3}
                    label={'Comment'}
                    value={discussionComment}
                    onChange={(e) => setDiscussionComment(e.target.value)}
                    dataId={'discussion-comment-input'}
                />
                {(user.orgMemberships.some(x => x.orgId === dataInfoRequest.orgId && (x.isOrgAdmin || x.isDataProvider))
                        || user.isVivliAdmin || user.id === enquiry.requesterId ) &&
                    <div>
                        <ButtonComponent title="AddComment"
                                         style={{
                                             ...btnStyle,
                                             marginLeft: Size.PADDING,
                                         }} onClick={handleAddComment}>
                            Add Comment
                        </ButtonComponent>
                        <div style={paddingTop20}>
                            To save comments please click "Save" or "Save & Notify" button.
                        </div>
                    </div>

                }
            </div>
        </div>
    );
}
