﻿import React, {CSSProperties} from "react";
import {
    FieldHeaderComponent,
    TextFieldReadOnlyFormComponent,
    fiveAcrossStyle,
    fieldHeaderStyle
} from "@vivli/shared/components";
import {Styles} from "@vivli/shared/theme";
import {StudyDesignGridComponent} from "./study-design-grid.component";
import {PhaseEnum, RecruitmentStatusEnum} from "@vivli/features/studies/infrastructure/enum";
import moment from "moment";

const gridContainerStyle: CSSProperties = {
    width: '100%',
    marginLeft: '0px',
    paddingBottom: '10px',
}

const mapOverallStatusToMessage = (status: RecruitmentStatusEnum) => {
    switch (status) {
        case RecruitmentStatusEnum.Completed:
            return 'Complete';
        case RecruitmentStatusEnum.Terminated:
            return 'Terminated';
    }
    return status;
}

export const DesignHistoryComponent = ({study}) => {
    return (
        <>
            <FieldHeaderComponent title={'STUDY DESIGN'} style={fieldHeaderStyle} />
            <div style={Styles.FORM_ROW}>
                <div style={gridContainerStyle}>
                    <StudyDesignGridComponent study={study}/>
                </div>
            </div>

            <FieldHeaderComponent title={'STUDY HISTORY'} style={fieldHeaderStyle} />
            <div style={Styles.FORM_ROW}>
                <TextFieldReadOnlyFormComponent
                    label='Study Start Date'
                    style={fiveAcrossStyle}
                    defaultValue={moment(study?.studyStartDate).format('MM/DD/YYYY')}
                    readonly={true}
                />
                <TextFieldReadOnlyFormComponent
                    label='Actual Study Completion Date'
                    style={fiveAcrossStyle}
                    defaultValue={moment(study?.actualStudyCompletionDate).format('MM/DD/YYYY')}
                    readonly={true}
                />
                <TextFieldReadOnlyFormComponent
                    label='TotalEnrollment'
                    style={fiveAcrossStyle}
                    defaultValue={study?.actualEnrollment?.toString()}
                    readonly={true}
                />
                <TextFieldReadOnlyFormComponent
                    label='Phase'
                    style={fiveAcrossStyle}
                    defaultValue={study?.phase ? PhaseEnum[study.phase] : 'Not available'}
                    readonly={true}
                />
                <TextFieldReadOnlyFormComponent
                    label='Overall Status'
                    style={fiveAcrossStyle}
                    defaultValue={study?.overallStatus ? mapOverallStatusToMessage(study.overallStatus) : ''}
                    readonly={true}
                />
            </div>
        </>
    )
}
