import {AdminHeaderComponent, FieldHeaderComponent, TabComponent, TabsComponent,} from '@vivli/shared/components';
import React, {CSSProperties, useState} from 'react';
import {DatasetTabEnum} from '@vivli/features/datasets/infrastructure/enum';
import {useDatasetContext} from '@vivli/features/datasets/infrastructure/context';
import {Color, Size} from '@vivli/shared/theme';
import {AmrDatasetDetailsComponent} from './amr-dataset-details.component';
import {DatasetAttachedDocumentsComponent} from './dataset-attached-documents.component';
import {AssignedAppTypeEnum} from '@vivli/shared/infrastructure/enum';
import {TrialsDatasetDetailsComponent} from './trials-dataset-details.component';
import {DatasetDownloadComponent} from './dataset-download.component';
import {DatasetViewComponent} from './dataset-view.component';
import {useActiveUser, useConfigService} from '@vivli/core/infrastructure/context';
import {useAssignedAppType} from '@vivli/core/infrastructure/hook';
import {DatasetAdminDetailsContainerComponent} from './admin-details/dataset-admin-details-container.component';
import {DatasetResearchTeamComponent} from './research-team/dataset-research-team.component';
import {StudyStatusEnum} from '@vivli/features/studies/infrastructure/enum';
import {UsageCitationsContainerComponent} from "@vivli/features/studies/components";

enum tabEnum {
    details,
    documents,
    adminDetails,
    download,
}

const headerContainerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
    position: 'relative',
    padding: '0 40px',
};
const headerTitleContainerStyle: CSSProperties = {
    marginLeft: '-40px',
    padding: '0px',
    color: Color.DARK_GRAY,
    fontWeight: Size.FontWeight.Bold,
};

const headerTitleStyle: CSSProperties = {
    fontSize: Size.FontSize.Larger,
    fontWeight: Size.FontWeight.Bold,
    color: Color.BLACK,
};

const smallHeaderStyle: CSSProperties = {
    fontWeight: 'normal',
    fontSize: Size.FontSize.Large,
};

const tombstoneStyle: CSSProperties = {
    fontWeight: Size.FontWeight.SemiBold,
    fontSize: Size.FontSize.Large,
    marginBottom: '40px',
    color: Color.DARK_GRAY,
    paddingBottom: '20px',
};

export const DatasetTabsComponent = () => {
    const { study, showDownload, isLanding } = useDatasetContext();
    const showDownloadAdmin = useActiveUser()?.isVivliAdmin;
    const config = useConfigService();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const [activeTab, setActiveTab] = useState<number>(tabEnum.details);

    const programName = study?.amrDataset?.program;
    const acronymString = study?.acronym ? ' (' + study?.acronym + ')' : '';

    const showResearchTeam = !isAmr && study?.researchTeam?.length > 0;

    /* We're showing the small header as well as the title to help with Google Dataset Search */
    const studySmallHeader =
        'Vivli Digital Object Identifier Information Sheet: Study Metadata DOI';
    const amrSmallHeader = 'AMR Digital Object Identifier Information Sheet: Dataset DOI';
    const smallHeader = isAmr ? amrSmallHeader : studySmallHeader;
    const titleToDisplay = isAmr ? programName : study?.studyTitle + acronymString;

    //tombstone pages for DOIs are for studies that were once posted but have been cancelled or put back to an earlier status
    const isTombstoneDoi =
        isLanding &&
        study.doiStem &&
        study.status != StudyStatusEnum.Posted &&
        study.status != StudyStatusEnum.Draft;
    const tombstoneLabel = isAmr
        ? 'This dataset data package is no longer available for requesting from the AMR platform'
        : 'This study data package is no longer available for requesting from the Vivli Platform';

    const detailsTabTitle = isAmr ? DatasetTabEnum.DatasetDetails : DatasetTabEnum.StudyDetails;
    const detailsComponent = isAmr ? (
        <AmrDatasetDetailsComponent dataset={study.amrDataset} />
    ) : (
        <TrialsDatasetDetailsComponent />
    );
    const documentsTabTitle = isAmr ? DatasetTabEnum.Documents : DatasetTabEnum.StudyDocuments;
    const downloadTabTitle = isAmr ? DatasetTabEnum.Download : (!showDownloadAdmin ? DatasetTabEnum.StudyDownload : DatasetTabEnum.StudyView);

    return (
        <div style={{ height: '100%' }}>
            <div style={headerContainerStyle}>
                <AdminHeaderComponent style={{ marginBottom: 10 }}>
                    <div style={headerTitleContainerStyle}>
                        {isLanding && <span style={smallHeaderStyle}>{smallHeader}</span>}
                        <FieldHeaderComponent title={titleToDisplay} style={headerTitleStyle} />
                        {isTombstoneDoi && <span style={tombstoneStyle}>{tombstoneLabel}</span>}
                    </div>
                </AdminHeaderComponent>

                <TabsComponent defaultTab={activeTab} commandOnClick={() => {}}>
                    <TabComponent title={detailsTabTitle} onTabClick={() => {}}>
                        <div>{detailsComponent}</div>
                    </TabComponent>

                    <TabComponent title={documentsTabTitle} onTabClick={() => {}}>
                        <div style={{ height: '100%' }}>
                            <DatasetAttachedDocumentsComponent />
                        </div>
                    </TabComponent>

                    <TabComponent title={DatasetTabEnum.AdminDetails} onTabClick={() => {}}>
                        <div>
                            <DatasetAdminDetailsContainerComponent />
                        </div>
                    </TabComponent>

                    {showDownload  && (
                        <TabComponent title={downloadTabTitle} onTabClick={() => {}}>
                            <div>
                                <DatasetDownloadComponent />
                            </div>
                        </TabComponent>
                    )}

                    {showDownloadAdmin && (
                        <TabComponent title={downloadTabTitle} onTabClick={() => {}}>
                            <div>
                                <DatasetViewComponent />
                            </div>
                        </TabComponent>
                    )}

                    {config.irusCountOn && (
                        <TabComponent title={DatasetTabEnum.Usage} onTabClick={() => {}}>
                            <div>
                                <UsageCitationsContainerComponent studyId={study.id} studyMetadataDoi={study.studyMetadataDoi} studyPostedDate={study.postedDate}/>
                            </div>
                        </TabComponent>
                    )}

                    {showResearchTeam && (
                        <TabComponent title={DatasetTabEnum.ResearchTeam} onTabClick={() => {}}>
                            <div>
                                <DatasetResearchTeamComponent />
                            </div>
                        </TabComponent>
                    )}
                </TabsComponent>
            </div>
        </div>
    );
};
