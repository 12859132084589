﻿import {CitationsGridComponent} from "./citations-grid.component";
import {LoadIndicatorCenteredComponent} from "@vivli/shared/components";
import React from "react";
import {useCitationsContext} from "@vivli/shared/features/citations/infrastructure/context";
import {Styles} from "@vivli/shared/theme";
import {CitationsAddButtonsComponent} from "./citation-add-buttons.component";

interface CitationsContainerComponentProps {
    objectId: string;
    isStudy: boolean;
    doUpdate: boolean;
    dataRequestDoiStem: string;
}

export const CitationsContainerComponent = ({objectId, isStudy, doUpdate, dataRequestDoiStem
}: CitationsContainerComponentProps) => {

    const {citations, canDeleteOrEdit, isLoading} = useCitationsContext();
    const canModify = canDeleteOrEdit() && !isStudy;

    if (!citations) {
        return <LoadIndicatorCenteredComponent/>;
    }

    return (
        <div>
            {canModify &&
                <div style={Styles.FORM_ROW}>
                        <CitationsAddButtonsComponent
                            objectId={objectId}
                            isStudy={isStudy}
                            dataRequestDoiStem={dataRequestDoiStem}/>
                </div>
            }
            {isLoading && <LoadIndicatorCenteredComponent/>}

            <CitationsGridComponent
                citations={citations}
                dataRequestDoiStem={dataRequestDoiStem}
                emptyMessage={'No citations found'}
                hideFloatingFilter={true}
                hideHeader={true}
                autoHeight={true}
                dataRefreshing={false}/>
        </div>

    );

}
