import React from 'react';
import {GridComponent} from '@vivli/shared/components';
import {ColDef, GridApi} from 'ag-grid-community';
import {Color, Size} from '@vivli/shared/theme';

interface AboutDataGridComponentProps {
    aboutTheDataList: any[];
    type?: string;
    onGridReady?: (gridApi: GridApi) => void;
    emptyMessage?: string;
    hideFloatingFilter?: boolean;
    hideHeader?: boolean;
    autoHeight?: boolean;
    dataRefreshing?: boolean;
}

export const AboutDataGridComponent = (
    {
        aboutTheDataList,
        onGridReady,
        emptyMessage,
        hideFloatingFilter,
        autoHeight,
        dataRefreshing
    }: AboutDataGridComponentProps) => {

    //These are not filterable per Vivli,
    //but leaving the property in here so it's obvious in case Vivli
    //changes their mind
    const columnDefs: ColDef[] = [
        {
            field: 'key',
            headerName: '',
            cellStyle: {fontWeight: Size.FontWeight.SemiBold, color: Color.DARK_GRAY, border: 'right'},
            filter: false,
            sortable: true,
            tooltipField: 'About the Data Categories',
            headerTooltip: 'Title',
            width: 500,
            minWidth: 500,
            wrapText: true,
            pinned: 'left',
            hide: false,
        }, {
            field: 'value',
            headerName: '',
            filter: false,
            sortable: true,
            headerTooltip: 'Description',
            wrapText: true,
            hide: false,
        }
    ]

    return (
        <GridComponent
            rowData={aboutTheDataList}
            fullWidthColumns={true}
            onGridReady={onGridReady}
            columns={columnDefs}
            emptyMessage={emptyMessage}
            hideFloatingFilter={true}
            hideHeader={true}
            autoHeight={autoHeight}
            pagination={false}
            dataRefreshing={dataRefreshing}
            rowHeight={50}
        />
    )
}
