﻿import React, {CSSProperties, useEffect} from 'react';
import {
    fourAcrossStyle,
    fourSmallSplitAcrossStyle,
    LinkFieldComponent,
    oneAcrossStyle,
    TextFieldReadOnlyFormComponent,
    TextFormFieldComponent,
    threeAcrossStyle,
} from '@vivli/shared/components';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { Styles } from '@vivli/shared/theme';
import { CollaboratorsAndSecondaryIdsComponent } from './collaborators-and-secondary-ids';
import { PublishingAndContactingComponent } from './publishing-and-contacting.component';
import { useStudyPrimaryRegistryUrlHook } from '@vivli/features/studies/infrastructure/hook';
import {IUser} from "@vivli/shared/infrastructure/interface";

const tightRow: CSSProperties = {
    ...Styles.FORM_ROW,
    marginBottom: 0,
};

export const notAsSmallFourAcrossStyle: CSSProperties = {
    ...fourSmallSplitAcrossStyle,
    flex: '1 1 14',
    maxWidth: '14%',
};

//component for shared use by both org admins and vivli admins
//ct only
interface AdminCtAdministrativeSharedComponentProps {
    study: IStudy;
    isVivliAdmin: boolean;
    otherResourcesLabel: string;
    user?: IUser;
}

export const AdminCtAdministrativeSharedComponent = ({
    study,
    isVivliAdmin,
    otherResourcesLabel,
    user,
}: AdminCtAdministrativeSharedComponentProps) => {
    const showLinkDoi = study.studyMetadataDoi?.indexOf('DOINotCreated') < 0;
    const AltDoi = study.alternateDoi;
    const doiStem = !study.doiStem ? '' : `VIV${study.doiStem}`;
    const {registryUrl} = useStudyPrimaryRegistryUrlHook();
    const nctString = registryUrl(study);
    const userIsReadOnlyForGeneralFields = !isVivliAdmin;

    const PrimaryDOIField = () => {
        if (AltDoi) {
            return (
                <LinkFieldComponent
                    label="Primary DOI"
                    linkContent={study.alternateDoi}
                    linkTitle={study.alternateDoi}
                    style={fourAcrossStyle}
                    readonly={true}
                />
            )
        }
        if(showLinkDoi){
            return (
                <LinkFieldComponent
                    label="Primary DOI"
                    linkContent={study.studyMetadataDoi}
                    linkTitle={study.studyMetadataDoi}
                    style={threeAcrossStyle}
                />
            )
        }
        return (
            <TextFormFieldComponent
                name={`studyMetadataDoi`}
                label="Primary DOI"
                style={fourAcrossStyle}
                readonly={true}
            />
        )
    }


    return (
        <div style={{ width: '100%' }}>
            <div style={Styles.FORM_ROW}>
                <PrimaryDOIField />
                <TextFieldReadOnlyFormComponent
                    label={'Vivli ID'}
                    style={notAsSmallFourAcrossStyle}
                    defaultValue={doiStem}
                    readonly={true}
                />
                <TextFormFieldComponent
                    name={`sponsorProtocolId`}
                    label="Sponsor Protocol Id"
                    style={fourAcrossStyle}
                    readonly={!isVivliAdmin}
                />
                <TextFormFieldComponent
                    name={`acronym`}
                    label="Acronym"
                    style={fourAcrossStyle}
                    readonly={!isVivliAdmin}
                />
            </div>
            {study.studyIPDDataPackageDoi && (
                <div style={tightRow}>
                    <LinkFieldComponent
                        linkTitle={study.studyIPDDataPackageDoi}
                        linkContent={study.studyIPDDataPackageDoi}
                        label={'Data Package DOI(s) Available for this Study'}
                        style={oneAcrossStyle}
                    />
                </div>
            )}
            <div style={Styles.FORM_ROW}>
                <TextFormFieldComponent
                    name="orgName"
                    label={'Data Contributor Organization Name'}
                    style={threeAcrossStyle}
                    readonly={true}
                />
                <TextFormFieldComponent
                    name="leadSponsor.agency"
                    label={'Lead Sponsor Agency'}
                    style={threeAcrossStyle}
                    readonly={!isVivliAdmin}
                />
                <TextFormFieldComponent
                    name="leadSponsor.agencyClass"
                    label={'Lead Sponsor Agency Class'}
                    style={threeAcrossStyle}
                    readonly={true}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <TextFormFieldComponent
                    name="alternativeMetadataDocumentUri"
                    label={otherResourcesLabel}
                    style={oneAcrossStyle}
                    readonly={true}
                />
            </div>

            <div style={Styles.FORM_ROW}>
                <TextFormFieldComponent
                    name={'registryInfo[0].registryName'}
                    label={'Primary Registry Name'}
                    style={threeAcrossStyle}
                    readonly={userIsReadOnlyForGeneralFields}
                />
                <TextFormFieldComponent
                    name={'registryInfo[0].registryId'}
                    label={'Primary Registry Id'}
                    style={threeAcrossStyle}
                    readonly={userIsReadOnlyForGeneralFields}
                />
                <LinkFieldComponent
                    linkTitle={nctString}
                    linkContent={nctString}
                    label={'Primary Registry Url'}
                    style={threeAcrossStyle}
                />
            </div>
            <CollaboratorsAndSecondaryIdsComponent
                study={study}
                isVivliAdmin={isVivliAdmin}
            />
            <PublishingAndContactingComponent />
        </div>
    );
};
