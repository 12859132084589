﻿import React from "react";
import {
    internalMenuButtonContainerStyle,
    internalMenuButtonOuterContainerStyle,
    internalMenuTextStyle
} from "./internal-link-menu-styles";
import {useNavigate} from "react-router-dom";

export const EnquiryLinkComponent = () => {

    const navigate = useNavigate();
    const handleClick = () => {
        const enquiryLink: string = '/enquiries';
        navigate(enquiryLink)
    }

    return (
        <div style={internalMenuButtonOuterContainerStyle}>
            <div
                style={internalMenuButtonContainerStyle}
                onClick={handleClick}
                className='header_enquiry'
                data-test-id={'header_enquiry'}
            >
                <div style={internalMenuTextStyle}>
                    ENQUIRY
                </div>
            </div>
        </div>
    )
}
