import React from 'react';
import {
    DataPackageBaseComponent,
    DataPackageComponent,
    DataPackageContext
} from "@vivli/shared/features/data-package/components";

interface DownloadOnlyDataPackageFeatureProps {
    dataPackageId?: string;
    datasetMetaDataDoi?: string;
    studyOrgId?: string;
    onReady?: () => void;
    onClose?: () => void;
    disallowDownload?: boolean;
}

export const DownloadOnlyDataPackageFeature = (
    {
        dataPackageId,
        datasetMetaDataDoi,
        studyOrgId,
        onReady,
        onClose,
        disallowDownload
    }: DownloadOnlyDataPackageFeatureProps
) => (
    <DataPackageBaseComponent dataPackageId={dataPackageId}>
        <DataPackageContext.Consumer>
            {dataPackage => <DataPackageComponent
                dataPackage={dataPackage}
                readOnly={true}
                allowDownload={!disallowDownload}
                allowUpload={false}
                allowSubmit={false}
                onReady={onReady}
                datasetMetaDataDoi ={datasetMetaDataDoi}
                studyOrgId ={studyOrgId}
                recordFileDownload={true}
                onClose={onClose}
            />}
        </DataPackageContext.Consumer>
    </DataPackageBaseComponent>
)

