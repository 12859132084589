﻿import React, {CSSProperties, useState} from 'react';
import {DataRequestStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {AdminHeaderButtonComponent} from "@vivli/shared/components";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {useDataRequestsService} from "@vivli/features/data-requests/infrastructure/context";
import {finalize, first} from "rxjs/operators";
import {IDataRequest, IDataRequestSummary} from "@vivli/features/data-requests/infrastructure/interface";

interface CancelButtonComponentProps {
    style?: CSSProperties;
    dataRequest: IDataRequest | IDataRequestSummary;
    setIsUpdatingStatus?: (isUpdating: boolean) => void;
    updateDataRequest?: (updatedDataRequest: IDataRequest) => void;
    isDisabled?: boolean;
    dataId?: string;
}

export const CancelButtonComponent = (
    {
        style,
        setIsUpdatingStatus,
        isDisabled,
        dataRequest,
        updateDataRequest,
        dataId
    }: CancelButtonComponentProps) =>
{
    const activeUser = useActiveUser();
    const modalService = useModalService();
    const dataRequestsService = useDataRequestsService();
    const [isLoading, setIsLoading] = useState(false);

    const cancelButtonTitle = 'Cancel';

    const handleUpdateDataRequest = (updatedDataRequest: IDataRequest) => {
        updateDataRequest && updateDataRequest(updatedDataRequest);
    }

    const handleConfirmClick = (e) => {
        e.stopPropagation();
        const confirmTitle = 'Confirm Cancel Data Request';
        const confirmText = activeUser.isVivliAdmin ? 'Proceeding will allow you to take actions on behalf of a Data Requestor.  Proceed?' : 'Are you sure you want to cancel the Data Request. This cannot be undone.';
        modalService.confirm(confirmText, {
            title: confirmTitle,
            showTextAreaPrompt: true,
            promptLabel: 'Enter explanation',
            requireComment: true,
            onConfirm: ({comment}) => {
                handleOnConfirmed(comment)
            }
        })
    }

    const updateLoadingStatus = (status: boolean) => {
        setIsUpdatingStatus && setIsUpdatingStatus(status);
        setIsLoading(status);
    }

    const handleOnConfirmed = (comment?: string) => {
        if (comment?.length > 0) {
            updateLoadingStatus(true);
            dataRequestsService
                .setDataRequestStatus(dataRequest.id, DataRequestStatusEnum.Cancelled, 'Cancelling')
                .pipe(first(), finalize(() => updateLoadingStatus(false)))
                .subscribe(handleUpdateDataRequest, modalService.error);
        }
    }

    return (
        <AdminHeaderButtonComponent
            dataId={dataId}
            title={cancelButtonTitle}
            onClick={handleConfirmClick}
            isLoading={isLoading}
            isDisabled={isDisabled || isLoading}
            style={style}
        />
    )
}
